import React, { useState, useEffect, useContext } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import assetContext from "../../context/AssetsContext/AssetContext";

function PropertyRequirement() {
  const [selectedPropertyType, setSelectedPropertyType] = useState(null);
  const [selectedConfigType, setSelectedConfigType] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const propertyTypes = ["Apartment", "Villa", "Plot"];
  const configTypes = ["1BHK", "2BHK", "3BHK", "4BHK"];
  const priceRange = ["<50L", "50L-1CR", "1CR-2CR", ">2CR"];
  const locations = ["Bengaluru", "Bhubaneswar"];

  const handlePropertyTypeClick = (type) => {
    setSelectedPropertyType(type);
  };

  const handleConfigTypeTypeClick = (type) => {
    setSelectedConfigType(type);
  };
  const handlePriceRangeClick = (type) => {
    setSelectedPrice(type);
  };
  const handleLocationClick = (type) => {
    setSelectedLocation(type);
  };
  const context = useContext(assetContext);

  const { registerRequirement } = context;

  const handleClick = async (data) => {
    // SetSpinnerStatus(true);

    // const errors = validateAssets(data);

    registerRequirement({
      name: data.name,
      email: data.email,
      phone: data.phone,
      message: data.message,
    });

    //  if (errors.length === 0) {
    //   toast.success("Validation successful!");

    //    addAssetx({ data });
    // } else {
    //   errors.map((error, index) => {
    //     toast.error(error);
    //   });
    //  }

    // SetSpinnerStatus(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    handleClick(data);
  };

  const handleData = (key, value) => {
    setData((prevItem) => ({
      ...prevItem,
      [key]: value,
    }));
  };

  const [data, setData] = useState({
    uid: 0,
    name: "",
    phone: "",
    message: "",
    status: 1,
    property_location: '{"data": null}',
    property_locality: '{"data": null}',
    property_status: '{"data": null}',
    property_type: '{"data": null}',
    starting_price: 0.1,
    maximum_price: 0.2,
  });

  return (
    <div className=" mt-10 py-14 sm:py-22 px-8 relative w-full max-w-7xl  bg-cover border border-gray-300 rounded-md shadow-md bg-white py-4">
      <div className="mb-6 flex max-w-7xl flex-row justify-between sm:items-center sm:flex-colum">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h1 className="text-2xl text-gray-800 font-semibold">
            Not able to find what you are looking for?
          </h1>
          <h1 className="text-2xl text-gray-800 font-semibold">
            Tell us your requirement
          </h1>
          <div
            style={{
              width: "5vw",
              backgroundColor: "purple",
              height: 3,
              marginTop: 10,
            }}
          ></div>
        </div>
      </div>
      <ToastContainer position="top-right" />

      <div className="flex flex-col p-4 max-w-screen-lg mx-auto space-y-8">
        <div className="flex flex-col md:flex-row ">
          <div className="flex flex-1 flex-col space-y-2 mt-4">
            <h3 className="text-lg font-semibold">Property Type</h3>
            <div className="flex space-x-2">
              {propertyTypes.map((type) => (
                <div
                  key={type}
                  onClick={() => handlePropertyTypeClick(type)}
                  className={`cursor-pointer px-4 py-1 text-sm border rounded-md ${
                    selectedPropertyType === type
                      ? "bg-indigo-600 text-white"
                      : "bg-gray-200 text-gray-800"
                  }`}
                >
                  {type}
                </div>
              ))}
            </div>
          </div>

          <div className="flex flex-1 flex-col space-y-2 mt-4">
            <h3 className="text-lg font-semibold">Property Configuration</h3>
            <div className="flex space-x-2">
              {configTypes.map((type) => (
                <div
                  key={type}
                  onClick={() => handleConfigTypeTypeClick(type)}
                  className={`cursor-pointer px-4 py-1 text-sm border rounded-md ${
                    selectedConfigType === type
                      ? "bg-indigo-600 text-white"
                      : "bg-gray-200 text-gray-800"
                  }`}
                >
                  {type}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row">
          <div className="flex flex-1 flex-col space-y-2 mt-4">
            <h3 className="text-lg font-semibold">Property Budget</h3>
            <div className="flex space-x-2">
              {priceRange.map((type) => (
                <div
                  key={type}
                  onClick={() => handlePriceRangeClick(type)}
                  className={`cursor-pointer px-4 py-1 text-sm border rounded-md ${
                    selectedPrice === type
                      ? "bg-indigo-600 text-white"
                      : "bg-gray-200 text-gray-800"
                  }`}
                >
                  {type}
                </div>
              ))}
            </div>
          </div>

          <div className="flex flex-1 flex-col space-y-2 mt-4">
            <h3 className="text-lg font-semibold">Property Location</h3>
            <div className="flex space-x-2">
              {locations.map((type) => (
                <div
                  key={type}
                  onClick={() => handleLocationClick(type)}
                  className={`cursor-pointer px-4 py-1 border text-sm rounded-md ${
                    selectedLocation === type
                      ? "bg-indigo-600 text-white"
                      : "bg-gray-200 text-gray-800"
                  }`}
                >
                  {type}
                </div>
              ))}
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="space-y-5 mt-12 lg:pb-12">
          <div className="flex flex-col md:space-y-2">
            <div className="flex justify-between flex-col md:flex-row">
              <div className="flex flex-1 flex-col space-y-2 pr-2 mt-2">
                <input
                  type="text"
                  placeholder="Name"
                  required
                  className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-1 focus:ring-indigo-600"
                />
              </div>

              <div className="flex flex-1 flex-col space-y-2 pr-2 mt-2">
                <input
                  type="text"
                  placeholder="Phone"
                  required
                  className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-1 focus:ring-indigo-600"
                />
              </div>
            </div>

            <div className="flex justify-between flex-col md:flex-row">
              <div className="flex flex-1  flex-col space-y-2 pr-2 mt-2">
                <input
                  type="email"
                  placeholder="Email"
                  required
                  className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-1 focus:ring-indigo-600"
                />
              </div>

              <div className="flex flex-1 flex-col space-y-2 pr-2 mt-2">
                <input
                  type="text"
                  required
                  placeholder="message"
                  className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-1 focus:ring-indigo-600"
                />
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="px-4 py-3 text-sm text-white duration-100 bg-indigo-600 rounded-lg shadow-md focus:shadow-none ring-offset-2 ring-indigo-600 focus:ring-2"
          >
            Request a call back
          </button>
        </form>
      </div>
    </div>
  );
}

export default PropertyRequirement;
