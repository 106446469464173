import React, {  } from "react";

const RERA = ({ name, pRERA,hRERA }) => {
  // const [isExpanded, setIsExpanded] = useState(false);

  // const toggleReadMore = () => {
  //   setIsExpanded(!isExpanded);
  // };
  // const text =
  //   "Provident Botanico offers well-designed comfort and luxury homes in East Bangalore, Bangalore. The project features 1275 units spread across 17.12 acres, offering 2 BHK and 3 BHK apartments ranging from 986 sqft to 1480 sqft. With a clubhouse spread over 20000 sqft, vaastu compliant homes, and 75% open and green spaces, Provident Botanico provides a tranquil and harmonious living environment. Located close to Bangalore International Academy and with easy access to Whitefield Railway Station and Whitefield Main Road, the project boasts excellent connectivity and convenience.";

  return (
    <div className="mt-10  py-14 sm:py-22 px-8 relative w-full max-w-7xl  bg-cover border border-gray-300 rounded-md shadow-md bg-white py-4">
      <div className="mx-auto max-w-2xl lg:mx-0">
        <h1 className="text-2xl text-gray-800 font-semibold">
          {name} RERA Details
        </h1>
        <div
          style={{
            width: "5vw",
            backgroundColor: "purple",
            height: 3,
            marginTop: 10,
          }}
        ></div>
      </div>
      <br/>

      <h2>Project RERA</h2>
      <h1 style={{fontWeight:550,color:"grey"}}>
         {pRERA}
      </h1>
      <br/>
      <h2>Homznoffiz RERA</h2>

      <h1 style={{fontWeight:550,color:"grey"}}>
        {hRERA}
         
      </h1>
    </div>
  );
};

export default RERA;
