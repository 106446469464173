import React from "react";
 import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IMAGE_PATH } from "../../constant";

const MasterPlan = ({name,url}) => {
   
  return (
    <div className=" mt-10 py-14 sm:py-22 px-8 relative w-full max-w-7xl  bg-cover border border-gray-300 rounded-md shadow-md bg-white py-4">
      <div className="mb-6 flex max-w-7xl flex-row justify-between sm:items-center sm:flex-colum">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h1 className="text-2xl text-gray-800 font-semibold">
            {name} Master Plan
          </h1>
          <div
            style={{
              width: "5vw",
              backgroundColor: "purple",
              height: 3,
              marginTop: 10,
            }}
          ></div>
        </div>
      
      </div>
      <div
        className="relative w-full max-w-7xl h-[600px] bg-cover rounded-md bg-white py-4"
        style={{
          backgroundImage: `url(${IMAGE_PATH + url})`,
        }}
      >
     
      </div>
    </div>
  );
};

export default MasterPlan;
