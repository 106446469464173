import { BASE_URL } from "../constant";
import AdminContext from "../context/AdminContext/AdminContext";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../provider/AuthProvider";

const AdminState = (propsx) => {
  const [admins, setAdmins] = useState([]);
  const [totalAdmins, setTotaltotalAdmins] = useState(0);

  const { setToken } = useAuth();

  const updateAdminStatusInList = (userId, newStatus, userList) => {
    return userList.map((user) => {
      if (user.id === userId) {
        return { ...user, status: newStatus };
      }
      return user;
    });
  };

  const UpdateAdmin = async (props) => {
    const { id, name, access, image } = props;

    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${BASE_URL}/admins/update/${id}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            name: name,

            access: access,
            image: image,
          }),
        });

        if (response.status === 200) {
          toast.success("Status updated successfully");
          // console.log(props.status);
          console.log(admins);
          const updatedUserList = updateAdminStatusInList(
            props.id,
            props.status,
            admins
          );
          console.log(updatedUserList);

          setAdmins(updatedUserList);

          resolve(true);
        } else if (response.status === 403) {
          toast.error("Your access has been revoked, Contact your Admin");
          reject("Update Failed");
        } else {
          toast.error("Couldn't update admin's status, Try again later");
          reject("Update Failed");
        }
      } catch (error) {
        console.error(error);
        toast.error("Couldn't update admin's status, Try again later");
        reject(error);
      }
    });
  };

  const getSearchAdmins = async (props) => {
    const { page, query_string } = props;

    const limit = 10;
    const offset = page ? (page - 1) * limit : 0;

    const queryParams = {
      query_string: query_string,
      limit: limit,
      offset: offset,
    };

    // API Call

    try {
      const response = await fetch(
        `${BASE_URL}/admins/getall?${new URLSearchParams(queryParams)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        //  console.log(json.total);
        const parsedData = json.data.map((item) => ({
          ...item,
          access: JSON.parse(item.access), // Convert property_bhk string to JSON
        }));
        setAdmins(parsedData);
        setTotaltotalAdmins(json.total);
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        //console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const CreateAdmin = async (props) => {
    const { name, phone, email, password, access, image } = props;
    // API Call
    try {
      const response = await fetch(`${BASE_URL}/admins/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          name: name,
          phone: phone,
          email: email,
          password: password,
          access: access,
          image: image,
        }),
      });
      if (response.status === 200) {
        // const json = response.json();
        toast.success("Admin creted successfully");
        getSearchAdmins({ page: 1, query_string: "" });
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        toast.error("Couldn't create admin, Try again later");

        //console.log("FAILED WITH STATUS CODE " + response.status.toString());
        //  //  props.showAlert("Unknown error occurred", "danger");
      }
    } catch (e) {
      toast.error("Couldn't create admin, Try again later");

      // console.log(e.message);
    }
  };

  const LoginAdmin = async (props) => {
    const { email, password } = props;
    // API Call
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${BASE_URL}/admins/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            email: email,
            password: password,
          }),
        });
        if (response.status === 200) {
          const json = await response.json();
          toast.success("Admin Login successful");

          const jsonString = JSON.stringify(json.data);
          localStorage.setItem("me", jsonString);

          setToken(json.data.token);
          resolve(true);
          // navigate("/", { replace: true });
        } else if (response.status === 403) {
          toast.error("Your access has been revoked, Contact your Admin");
          reject("Update Failed");
        } else {
          toast.error("Invalid Username or Password");
          reject("Update Failed");

          //console.log("FAILED WITH STATUS CODE " + response.status.toString());
          //  //  props.showAlert("Unknown error occurred", "danger");
        }
      } catch (error) {
        toast.error("Invalid Username or Password");

        reject(error);
      }
    });
  };
  // Delete bulk admins
  const deleteAdmins = async (props) => {
    const { ids, page } = props;
    console.log(ids);
    // API Call
    try {
      const response = await fetch(`${BASE_URL}/admins/bulk/delete`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ ids: ids }),
      });
      if (response.status === 200) {
        // const json = response.json();
        getSearchAdmins({ page: page, query_string: "" });
        toast.success("Deleted successfully");
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        toast.error("Couldn't delete admin(s), Try again later");

        //console.log("FAILED WITH STATUS CODE " + response.status.toString());
        //  //  props.showAlert("Unknown error occurred", "danger");
      }
    } catch (e) {
      toast.error("Couldn't delete admin(s), Try again later");

      // console.log(e.message);
    }
  };

  return (
    <AdminContext.Provider
      value={{
        LoginAdmin,

        getSearchAdmins,

        admins,
        totalAdmins,

        CreateAdmin,
        UpdateAdmin,

        deleteAdmins,
      }}
    >
      {propsx.children}
    </AdminContext.Provider>
  );
};
export default AdminState;
