import React, { useState, useContext } from "react";
import * as Select from "@radix-ui/react-select";
import Location from "../../assets/icons/location.png";
import { CiSearch } from "react-icons/ci";
import FilterModal from "./FiltersModal";
import { IoFilter } from "react-icons/io5";
import { IconButton } from "@mui/material";
import assetContext from "../../context/AssetsContext/AssetContext";

const SearchBarWithDropdown = ({ onDataReceived }) => {
  const [selectedCity, setSelectedCity] = useState(
    localStorage.getItem("city") !== null ? localStorage.getItem("city") : ""
  ); // State for selected city

  const context = useContext(assetContext);
  const {
 

    getSearchAssets,

   
  } = context;

  const [FilterDialog, ApplyFilters] = FilterModal();
  const [filters, setFilters] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const menuItems = ["Bengaluru", "Bhubaneswar"];
  const [currentPage, setCurrentPage] = useState(1);

  const handleFilters = async () => {
    const filtersx = await ApplyFilters();
    if (filtersx === null) {
    } else {
      setCurrentPage(1);
      getSearchAssets({
        page: currentPage,
        property_type: filtersx !== null ? filtersx.property_type : [],
        property_status: filtersx !== null ? filtersx.property_status : [],
        property_location: filtersx !== null ? filtersx.property_location : [],
        property_locality: filtersx !== null ? filtersx.property_locality : [],
         property_builder: filtersx !== null ? filtersx.property_builder : [],
        property_bhks: filtersx !== null ? filtersx.property_bhks : [],
        status: filtersx !== null ? filtersx.status : [],
        property_starting_price: 0,
        property_maximum_price:
          filtersx !== null ? filtersx.property_maximum_price : 15,
        property_name: searchQuery,
        is_featured: 0,
      is_infocused: 0,
      });
      setFilters({
        property_type: filtersx !== null ? filtersx.property_type : [],
        property_status: filtersx !== null ? filtersx.property_status : [],
        property_location: filtersx !== null ? filtersx.property_location : [],
        property_locality: filtersx !== null ? filtersx.property_locality : [],
 
        property_builder: filtersx !== null ? filtersx.property_builder : [],
        property_bhks: filtersx !== null ? filtersx.property_bhks : [],
        status: filtersx !== null ? filtersx.status : [],
        property_starting_price: 0,
        property_maximum_price:
          filtersx !== null ? filtersx.property_maximum_price : 15,
          is_featured: 0,
          is_infocused: 0,
      });

      onDataReceived({ text: searchQuery, filters: filtersx });

      // localStorage.setItem('property_type', JSON.stringify( filtersx !== null ? filtersx.property_type : []));
      // localStorage.setItem('property_status', JSON.stringify( filtersx !== null ? filtersx.property_status : []));
      // localStorage.setItem('property_location', JSON.stringify( filtersx !== null ? filtersx.property_location : []));
      // localStorage.setItem('property_builder', JSON.stringify( filtersx !== null ? filtersx.property_builder : []));
      // localStorage.setItem('property_bhks', JSON.stringify( filtersx !== null ? filtersx.property_bhks : []));
      // localStorage.setItem('cities', JSON.stringify( filtersx !== null ? filtersx.property_location : []));

      
    }
  };

  const handleSearch = async () => {
    onDataReceived({ text: searchQuery, filters: filters });
  };
  const handleChange = async (value) => {
    localStorage.setItem("city", value);
    setSelectedCity(value);

    console.log(value);

    onDataReceived({
      text: searchQuery,
      filters: {
        property_type: [],
        property_status: [],
        property_location: [value],
        property_locality:[],

        property_builder: [],
        property_bhks: [],
        status: [],
        property_starting_price: 0,
        property_maximum_price: 15,
        is_featured: 0,
        is_infocused: 0,
      },
    });
  };

  const inputStyle = {
    width: "100%",
    fontSize: "0.875rem" /* Equivalent to text-sm in Tailwind */,
    color: "black" /* Equivalent to gray-400 in Tailwind */,
  };

  const SelectItem = React.forwardRef(
    ({ children, className, ...props }, forwardedRef) => {
      // If you want to make this component more customizable you can use classnames and tailwind-merge library.
      return (
        <Select.Item
          className="flex items-center justify-between px-3 cursor-default py-2 duration-150 text-gray-600 data-[state=checked]:text-indigo-600 data-[state=checked]:bg-indigo-50 data-[highlighted]:text-indigo-600 data-[highlighted]:bg-indigo-50 data-[highlighted]:hover:text-indigo-600 data-[highlighted]:hover:bg-indigo-50 outline-none"
          {...props}
          ref={forwardedRef}
        >
          <Select.ItemText>
            <div className="pr-4 line-clamp-1 ">{children}</div>
          </Select.ItemText>
          <div className="w-6">
            <Select.ItemIndicator>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 text-indigo-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>{" "}
            </Select.ItemIndicator>
          </div>
        </Select.Item>
      );
    }
  );

  return (
    <div className="flex  justify-center items-center p-4">
      <div
        style={{ width: "100%" }}
        className="flex  flex-col justify-center items-center lg:flex-row    bg-white border border-gray-300 rounded-md shadow-md p-2 w-full max-w-2xl space-y-4 md:space-y-0"
      >
        <div className=" flex  justify-center items-center  w-full md:w-auto">
          <div
            style={{
              paddingLeft: 10,

              backgroundColor: "rgba(192, 192, 192,0.4 )",
              alignContent: "center",
              justifyContent: "center",
              borderRadius: 7,
            }}
          >
            <IconButton
              type="submit"
              edge="start"
              //disabled={checked.length === 0 ? true : false}
              //  onClick={() => handleDeleteUsers()}
              onClick={handleFilters}
            >
              <IoFilter color="grey" />
            </IconButton>
            <FilterDialog />
          </div>
          {/* <img
            src={Location}
            alt="Location"
            width={24}
            height={24}
            className="mr-2"
          /> */}
          <Select.Root
            value={selectedCity}
            onValueChange={(value) => handleChange(value)}
          >
            <div className="w-full bg-white z-1000">
              <Select.Trigger className="w-[100px] bg-white inline-flex items-center justify-between px-3 py-2 text-sm text-gray-600">
                <Select.Value placeholder="Select City" className="bg-white" />
              </Select.Trigger>
              <Select.Portal>
                <Select.Content
                  position="popper"
                  avoidCollisions={false}
                  className="w-[120px] z-1000 mt-3 overflow-y-auto bg-white border rounded-lg shadow-sm text-sm"
                >
                  <Select.Viewport>
                    {menuItems.map((item, idx) => (
                      <SelectItem key={idx} value={item}>
                        {item}
                      </SelectItem>
                    ))}
                  </Select.Viewport>
                </Select.Content>
              </Select.Portal>
            </div>
          </Select.Root>
        </div>

        <div
          style={{
            //   backgroundColor:"black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%", // Ensure the parent container uses full width
          }}
        >
          <div style={{ paddingRight: 5, color: "black" }}>|</div>
          <CiSearch color="black" />
          <div className="flex-grow mx-2">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search by project, builder, location ..."
              style={inputStyle}
              className="w-full py-2 px-4 bg-white border-0 focus:outline-none focus:ring-0"
            />
          </div>
          <div style={{ paddingRight: 5, color: "black" }}>|</div>
        </div>

        <button
          onClick={handleSearch}
          style={{ borderRadius: 7 }}
          className="bg-indigo-800 hover:bg-indigo-800 text-white font-bold py-2 px-6 focus:outline-none focus:shadow-outline"
        >
          Search
        </button>
      </div>
    </div>
  );
};

export default SearchBarWithDropdown;
