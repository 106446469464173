import React from "react";

import Apartment from "../../src/assets/images/apartment.jpg"; // Path to your image file
import Villa from "../../src/assets/images/villa.jpg"; // Path to your image file
import Farmhouse from "../../src/assets/images/farm_house.jpg"; // Path to your image file
import Plot from "../../src/assets/images/plot.jpg"; // Path to your image file
import Penthouse from "../../src/assets/images/penthouse.jpg"; // Path to your image file

const CategoryCard = () => {
  const showALL = async (type) => {
    const queryParams = new URLSearchParams({
      page: 1,
      property_type: [type],
      property_status: [],
      property_locality:[],

      property_location:  
      localStorage.getItem("city") !== null
        ? [localStorage.getItem("city")]
        : [],
      property_builder: [],
      property_bhks: [],
      status: [],
      property_starting_price: 0,
      property_maximum_price: 15,
      property_name: "",
      is_featured: 0,
      is_infocused: 0,
    }).toString();
    window.open(
      `/${localStorage.getItem("city")}/properties?${queryParams}`,
      "_blank"
    );
  };
  return (
    <div>
      <div
      //className="mx-auto max-w-7xl px-6 lg:px-8"
      >
        <h2
          style={{
            fontSize: 40,
            marginBottom: 30,
          }}
          className="text-center text-lg font-bold leading-none text-gray-900"
        >
          Browse through our property collection
        </h2>
        <h2 className="text-center text-lg font-semibold leading-none text-gray-900">
          curated for every property buyer
        </h2>

        <div className="mx-auto mt-10 grid max-w-lg grid-cols-2 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-3 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          <article
            // style={{ width:  300 }}
            className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg"
          >
            <img
              alt=""
              src={Apartment}
              className="absolute inset-0 h-full w-full object-cover"
            />

            <div
              onClick={() => showALL("Apartment")}
              className="relative bg-gradient-to-t bg-transparent from-gray-900/50 to-gray-900/25 pt-32 sm:pt-48 lg:pt-64"
            >
              <div className="p-4 sm:p-6 bg-transparent">
                <time
                  datetime="2022-10-10"
                  className="block text-xs text-white/90 bg-transparent"
                >
                  {" "}
                  10000+{" "}
                </time>

                <div>
                  <h3 className="mt-0.5 text-lg text-white bg-transparent">
                    Apartment
                  </h3>
                </div>

                
              </div>
            </div>
          </article>

          <article
            // style={{ width:  300 }}
            className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg"
          >
            <img
              alt=""
              src={Villa}
              className="absolute inset-0 h-full w-full object-cover"
            />

            <div
              onClick={() => showALL("Villa")}
              className="relative bg-gradient-to-t bg-transparent from-gray-900/50 to-gray-900/25 pt-32 sm:pt-48 lg:pt-64"
            >
              <div className="p-4 sm:p-6 bg-transparent">
                <time
                  datetime="2022-10-10"
                  className="block text-xs text-white/90 bg-transparent"
                >
                  {" "}
                  500+{" "}
                </time>

                <div>
                  <h3 className="mt-0.5 text-lg text-white bg-transparent">
                    Villa
                  </h3>
                </div>
              </div>
            </div>
          </article>

          <article
            // style={{ width:  300 }}
            className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg"
          >
            <img
              alt=""
              src={Farmhouse}
              className="absolute inset-0 h-full w-full object-cover"
            />

            <div
              onClick={() => showALL("Farm House")}
              className="relative bg-gradient-to-t bg-transparent from-gray-900/50 to-gray-900/25 pt-32 sm:pt-48 lg:pt-64"
            >
              <div className="p-4 sm:p-6 bg-transparent">
                <time
                  datetime="2022-10-10"
                  className="block text-xs text-white/90 bg-transparent"
                >
                  {" "}
                  1000+{" "}
                </time>

                <div>
                  <h3 className="mt-0.5 text-lg text-white bg-transparent">
                    Farm House
                  </h3>
                </div>
              </div>
            </div>
          </article>

          <article
            // style={{ width:  300 }}
            className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg"
          >
            <img
              alt=""
              src={Plot}
              className="absolute inset-0 h-full w-full object-cover"
            />

            <div
              onClick={() => showALL("Plot")}
              className="relative bg-gradient-to-t bg-transparent from-gray-900/50 to-gray-900/25 pt-32 sm:pt-48 lg:pt-64"
            >
              <div className="p-4 sm:p-6 bg-transparent">
                <time
                  datetime="2022-10-10"
                  className="block text-xs text-white/90 bg-transparent"
                >
                  {" "}
                  1000+{" "}
                </time>

                <div>
                  <h3 className="mt-0.5 text-lg text-white bg-transparent">
                    Plot
                  </h3>
                </div>
              </div>
            </div>
          </article>

          <article
            // style={{ width:  300 }}
            className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg"
          >
            <img
              alt=""
              src={Penthouse}
              className="absolute inset-0 h-full w-full object-cover"
            />

            <div
              onClick={() => showALL("Penthouse")}
              className="relative bg-gradient-to-t bg-transparent from-gray-900/50 to-gray-900/25 pt-32 sm:pt-48 lg:pt-64"
            >
              <div className="p-4 sm:p-6 bg-transparent">
                <time
                  datetime="2022-10-10"
                  className="block text-xs text-white/90 bg-transparent"
                >
                  {" "}
                  500+{" "}
                </time>

                <div>
                  <h3 className="mt-0.5 text-lg text-white bg-transparent">
                    Penthouse
                  </h3>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default CategoryCard;
