import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as Tabs from "@radix-ui/react-tabs";
import LeadRegister from "./LeadRegister";
import { IMAGE_PATH } from "../../constant";

const FloorPlan = ({ name, floorPlans, type }) => {
  const [fplans, setFPlans] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [selectedTab, setSelectedTab] = useState("");

  const parseIcon = (iconString) => {
    try {
      return JSON.parse(iconString);
    } catch (error) {
      console.error("Error parsing icon JSON:", error);
      return null;
    }
  };

  const handleChange = (value) => {
    setSelectedTab(value);
    setSelectedPlan(fplans[value] || []);
  };

  const groupByBHK = (Plans) => {
    return Plans.reduce(
      (acc, plan) => {
        const { type } = plan;
        if (!acc[type]) {
          acc[type] = [];
        }
        acc[type].push(plan);
        if (!acc.list.includes(type)) {
          acc.list.push(type);
        }
        return acc;
      },
      { list: [] }
    );
  };

  useEffect(() => {
    const groupedFloorPlans = groupByBHK(floorPlans);
    // console.log(groupedFloorPlans);
    setFPlans(groupedFloorPlans);
    if (groupedFloorPlans.list.length > 0) {
      setSelectedTab(groupedFloorPlans.list[0]);
      setSelectedPlan(groupedFloorPlans[groupedFloorPlans.list[0]]);
    }
  }, [floorPlans]);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          color: "white",
          backgroundColor: "grey",
          borderRadius: 40,
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          color: "white",
          backgroundColor: "grey",
          borderRadius: 40,
        }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="mt-10 py-14 sm:py-22 px-8 relative w-full max-w-7xl bg-cover border border-gray-300 rounded-md shadow-md bg-white py-4">
      <div className="mb-6 flex max-w-7xl flex-row justify-between sm:items-center sm:flex-colum">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h1 className="text-2xl text-gray-800 font-semibold">
            {name} Floor Plan
          </h1>
          <div
            style={{
              width: "5vw",
              backgroundColor: "purple",
              height: 3,
              marginTop: 10,
            }}
          ></div>
        </div>
      </div>

      <div className="">
        <Tabs.Root
          value={selectedTab}
          onValueChange={handleChange}
          className="max-w-screen-2xl mx-auto px-4 md:px-8 "
        >
          {fplans && (
            <Tabs.List
              className="w-full flex flex-wrap items-center gap-x-3 overflow-x-auto text-sm border-b justify-start"
              aria-label="Manage your account"
            >
              {fplans.list.map((item, idx) => (
                <Tabs.Trigger
                  key={idx}
                  className="group outline-none py-1.5 border-b-2 border-transparent text-gray-500 data-[state=active]:border-indigo-600 data-[state=active]:text-indigo-600"
                  value={item}
                >
                  <div className="py-1.5 px-3 rounded-lg duration-150 group-hover:text-indigo-600 group-hover:bg-gray-50 group-active:bg-gray-100 font-medium">
                    {item}
                  </div>
                </Tabs.Trigger>
              ))}
            </Tabs.List>
          )}

          <Tabs.Content className="py-6" value={selectedTab}>
            <div className="flex flex-row">
              <div className="w-full mt-10 mx-auto">
                {selectedPlan.length === 1 ? (
                  <div
                     
                    className="w-20vw border border-gray-300 rounded-md shadow-md bg-white"
                  >
                    <div className="w-full p-2 border-b rounded-md border-gray-300">
                      <img
                        src={
                          IMAGE_PATH +
                          selectedPlan[0].image.fieldname +
                          "/" +
                          selectedPlan[0].image.filename
                        }
                        // src={IMAGE_PATH + h.image}
                        alt={
                          IMAGE_PATH

                          // h[index].image.fieldname +
                          // "/" +
                          // h[index].image.filename
                        }
                        style={{ height: 280 }}
                        className="w-full object-cover rounded-md"
                      />
                    </div>

                    <div className="p-4">
                      <h1
                        style={{
                          backgroundColor: "transparent",
                          color: "black",
                          fontSize: 18,
                          fontWeight: 450,
                        }}
                      >
                        {selectedPlan[0].bhk}
                      </h1>

                      <h3
                        style={{
                          marginTop: 10,
                          color: "black",
                          fontSize: 16,
                          backgroundColor: "transparent",
                        }}
                      >
                        ₹ {selectedPlan[0].price} Cr
                      </h3>

                      <h1
                        style={{
                          fontSize: 15,
                          backgroundColor: "transparent",
                        }}
                      >
                        {selectedPlan[0].size} SQ FT
                      </h1>
                      <h1
                        style={{
                          fontSize: 15,
                          backgroundColor: "transparent",
                        }}
                      >
                        {type}
                      </h1>
                    </div>
                    <div className="flex justify-center items-center py-4">
                      <LeadRegister isShow={true} title="Enquiry Now" />
                    </div>
                  </div>
                ) : (
                  <Slider {...settings}>
                    {selectedPlan.map((h, index) => {
                      // console.log(h);

                      //  const parsedIcon = parseIcon(h.image);

                      // console.log(h.image.fieldname);

                      return (
                        <div
                          key={h.id}
                          className="border border-gray-300 rounded-md shadow-md bg-white"
                        >
                          <div className="w-full p-2 border-b rounded-md border-gray-300">
                            <img
                              src={
                                IMAGE_PATH +
                                h.image.fieldname +
                                "/" +
                                h.image.filename
                              }
                              // src={IMAGE_PATH + h.image}
                              alt={
                                IMAGE_PATH

                                // h[index].image.fieldname +
                                // "/" +
                                // h[index].image.filename
                              }
                              style={{ height: 280 }}
                              className="w-full object-cover rounded-md"
                            />
                          </div>

                          <div className="p-4">
                            <h1
                              style={{
                                backgroundColor: "transparent",
                                color: "black",
                                fontSize: 18,
                                fontWeight: 450,
                              }}
                            >
                              {h.bhk}
                            </h1>

                            <h3
                              style={{
                                marginTop: 10,
                                color: "black",
                                fontSize: 16,
                                backgroundColor: "transparent",
                              }}
                            >
                              ₹ {h.price} Cr
                            </h3>

                            <h1
                              style={{
                                fontSize: 15,
                                backgroundColor: "transparent",
                              }}
                            >
                              {h.size} SQ FT
                            </h1>
                            <h1
                              style={{
                                fontSize: 15,
                                backgroundColor: "transparent",
                              }}
                            >
                              {type}
                            </h1>
                          </div>
                          <div className="flex justify-center items-center py-4">
                            <LeadRegister isShow={true} title="Enquiry Now" />
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                )}
              </div>
            </div>
          </Tabs.Content>
        </Tabs.Root>
      </div>
    </div>
  );
};

export default FloorPlan;
