import React from "react";

import Approach from "../../src/assets/icons/affiliate.svg"; // Path to your image file
import Tracking from "../../src/assets/icons/tracking.svg"; // Path to your image file
import Managing from "../../src/assets/icons/managing.svg"; // Path to your image file
import Closure from "../../src/assets/icons/closure.svg";

const WhyUs = () => {
  const features = [
    {
      icon: Approach,
      title: "Approach",
      desc: "Crafting personalized and tailored strategies aligned with your vision and goals.",
    },
    {
      icon: Tracking,
      title: "Tracking",
      desc: "Transparent progress with advanced technology. From lead generation to conversion.",
    },
    {
      icon: Managing,
      title: "Managing",
      desc: "Efficient handling of all sales details. From coordinating viewings to negotiating offers.",
    },
    {
      icon: Closure,
      title: "Closure",
      desc: "Guidance for a smooth and successful transaction.",
    },
  ];

  return (
    <section className="py-14">
      <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
        <div className="  space-y-3">
          <p className="text-gray-800 text-3xl font-semibold sm:text-4xl">
            Your property is our responsibility
          </p>
          <p>Why Choose Us as Your Exclusive Sales Partner?</p>
        </div>
        <div className="mt-12">
          <ul className="grid gap-x-12 divide-y [&>.feature-1]:pl-0 sm:grid-cols-2 sm:gap-y-8 sm:divide-y-0 lg:divide-x lg:grid-cols-4 lg:gap-x-0">
            {features.map((item, idx) => (
              <li
                key={idx}
                className={`feature-${idx + 1} space-y-3 py-8 lg:px-12 sm:py-0`}
              >
                <div className="w-12 h-12 border text-indigo-600 rounded-full flex items-center justify-center">
                  
                  <img src= {item.icon} alt="Logo" />
                </div>
                <h4 className="text-lg text-gray-800 font-semibold">
                  {item.title}
                </h4>
                <p>{item.desc}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};
export default WhyUs;
