import TopNavBar from "./NavBar";
import Footer from "./Footer";
import React, { useState, useContext, useEffect } from "react";
import GalleryContext from "../context/GalleryContext/GalleryContext";
import { IMAGE_PATH } from "../constant";
const GalleryGrid = () => {
  const context = useContext(GalleryContext);
  const {
    // achievement,
    // getAchievement,

    getGallery,
    gallery,
  } = context;

  useEffect(() => {
    getGallery();
  }, []);

  return (
    <div className="">
      <div className="fixed top-0 bg-white w-full z-50">
        <TopNavBar />
      </div>
      <div className="p-10 mt-10 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {gallery.map((item, idx) => (
          <img
            className="h-auto max-w-full rounded-lg"
            src={IMAGE_PATH + "profilex/" + item.name}
            alt="Error"
          />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default GalleryGrid;
