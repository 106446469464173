import React, { useState, useContext, useEffect } from "react";
import GalleryContext from "../context/GalleryContext/GalleryContext";
import { IMAGE_PATH } from "../constant";
import { useNavigate } from "react-router-dom";

const GalleryList = () => {

  const context = useContext(GalleryContext);
  const {
    achievement,
    getAchievement,

    // getGallery,
    // gallery,
  } = context;

  useEffect(() => {
    getAchievement();
  }, []);

  const navigate = useNavigate();

  const handleClick = async (textx) => {
  
    navigate("/gallery");
  };
  return (
    <section className="py-28 ">
      <div className="relative z-10 bg-transparent max-w-screen-xl mx-auto px-4 md:px-8">
        <div className="max-w-xl sm:text-center md:mx-auto flex flex-col justify-center items-center">
          <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
            Honoring Achievements
          </h3>
          <p className="mt-3 text-gray-600">
            Explore the prestigious awards that highlight our dedication to
            excellence and industry leadership.
          </p>
          <button 
          onClick={handleClick}
          className="flex items-center gap-2 px-10 py-4 mt-6 text-white bg-indigo-600 rounded-lg duration-150 hover:bg-indigo-500 active:bg-indigo-700">
          
            Check Gallery
          </button>
        </div>
        <div className="mt-12 bg-transparent">
          <ul className="flex-wrap gap-x-12 bg-transparent gap-y-10 items-center space-y-8 sm:space-y-0 sm:flex xl:justify-center">
            {achievement.map((item, idx) => (
              <li
                key={idx}
                className="sm:max-w-[15rem] flex flex-col justify-center items-center bg-transparent"
              >
                <img
                  src={IMAGE_PATH + "profilex/" + item.name}
                  alt="Error"
                  className="w-[300px]"
                  style={{ borderRadius: 15 }}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div
        className="absolute inset-0 max-w-md mx-auto h-80 blur-[118px] sm:h-72"
        style={{
          background:
            "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)",
        }}
      ></div>
    </section>
  );
};
export default GalleryList;
