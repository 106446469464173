import React from "react";

const Testimonials = () => {
  const testimonials = [
    {
      avatar: "https://randomuser.me/api/portraits/men/73.jpg",
      name: "Sidhartha K.",
      title: "from our Webiste review",
      quote:
        "'Working with this real estate agency was an absolute pleasure. Their attention to detail and dedication to finding the perfect property exceeded my expectations. Highly recommend!'",
    },
    {
      avatar: "https://randomuser.me/api/portraits/women/22.jpg",
      name: "Swati Swain",
      title: "From our youtube comment",
      quote:
        "'Professionalism at its finest. From the initial consultation to the closing, every step was handled with precision and expertise. Trustworthy and reliable, I wouldn't hesitate to recommend them to anyone.'",
    },
    {
      avatar: "https://randomuser.me/api/portraits/men/35.jpg",
      name: "Akash Sha",
      title: "From our instagram comment",
      quote:
        "'Five-star service from start to finish. The team's market knowledge and strategic approach ensured a swift and successful sale. Couldn't have asked for a better experience. Thank you!'",
    },
  ];

  return (
    <section className="py-14  ">
      <div className="max-w-screen-xl mx-auto px-4 md:px-8">
        <div className="max-w-xl sm:text-center md:mx-auto">
          <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
            See what others saying about us
          </h3>
          <p className="mt-3 text-gray-600">
            Get inspired by the voices of our thrilled clients! Dive into our
            testimonials and see why we're the talk of the town.
          </p>
        </div>
        <div className="mt-12">
          <ul className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3 ">
            {testimonials.map((item, idx) => (
              <li key={idx} className="bg-white p-4 rounded-xl">
                <figure className="bg-white">
                  <div className="flex items-center gap-x-4 bg-white">
                    <img
                      src={item.avatar}
                      alt="Error"
                      className="w-16 h-16 rounded-full"
                    />
                    <div>
                      <span className="block text-gray-800 font-semibold bg-white">
                        {item.name}
                      </span>
                      <span className="block text-gray-600 text-sm mt-0.5 bg-white">
                        {item.title}
                      </span>
                    </div>
                  </div>
                  <blockquote>
                    <p className="mt-6 text-gray-700 bg-white">{item.quote}</p>
                  </blockquote>
                </figure>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};
export default Testimonials;
