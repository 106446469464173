import React, { useState, useContext, useEffect } from "react";
import assetContext from "../../context/AssetsContext/AssetContext";
import { IMAGE_PATH } from "../../constant";

const AboutBuilder = ({ name, property_builder }) => {
  const context = useContext(assetContext);
  const { builderDetails, getAssetBuilderDetails } = context;

  useEffect(() => {
    getAssetBuilderDetails({ name: property_builder });
  }, [property_builder]);

  const findEstablishedYear = (description) => {
    // Regular expression to find "Established Year" followed by a four-digit number
    const yearRegex = /Established Year (\d{4})/;

    // Match the year in the description using the regex
    const match = description.match(yearRegex);

    // Check if match was found
    if (match && match[1]) {
      // Extract the year from the matched string
      const year = match[1];
      return year;
    } else {
      // Return null if no match found
      return null;
    }
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const paragraph = `BuilderX has been delivering high-quality projects since its inception in 1999. With a portfolio that spans residential, commercial, and industrial sectors, BuilderX is committed to excellence in construction. Our team of experienced professionals ensures that every project is completed on time and within budget, maintaining the highest standards of quality and safety. BuilderX values integrity, quality, and client satisfaction, making us a trusted partner in the industry. We continuously innovate and adapt to meet the evolving needs of our clients, ensuring the delivery of cutting-edge solutions and sustainable practices. Our dedicated team is passionate about building a better future and leaving a lasting legacy in the communities we serve.`;

  const handleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className=" mt-10 py-14 sm:py-22 px-8 relative w-full max-w-7xl  bg-cover border border-gray-300 rounded-md shadow-md bg-white py-4">
      <div className="mb-6 flex max-w-7xl flex-row justify-between sm:items-center sm:flex-colum">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h1 className="text-2xl text-gray-800 font-semibold">
            {name} Builder
          </h1>
          <div
            style={{
              width: "5vw",
              backgroundColor: "purple",
              height: 3,
              marginTop: 10,
            }}
          ></div>
        </div>
      </div>
      {builderDetails != null ? (
        <div className="flex flex-col p-6 bg-white  max-w-7xl mx-auto">
          {/* First Row */}
          <div className="flex flex-col sm:flex-row items-center mb-6">
            {/* Builder Logo */}
            <img
              src={
                IMAGE_PATH + builderDetails.icon.fieldname + "/" + builderDetails.icon.filename
              }
              alt="Builder Logo"
              className="w-24 h-24 sm:w-32 sm:h-32 mb-4 sm:mb-0 sm:mr-6"
            />

            {/* Info Columns */}
            <div className="flex flex-col sm:flex-row sm:space-x-12 w-full">
              <div className="flex flex-col items-center sm:items-start mb-4 sm:mb-0">
                <span className="text-lg font-semibold">Projects</span>
                <span className="text-2xl font-bold">{builderDetails.assets_count}</span>
              </div>
              <div className="flex flex-col items-center sm:items-start">
                <span className="text-lg font-semibold">Established</span>
                <span className="text-2xl font-bold">{findEstablishedYear(builderDetails.description)}</span>
              </div>
            </div>
          </div>

          <div className="text-gray-700">
            <p>
              {isExpanded ?  builderDetails.description: `${builderDetails.description.substring(0, 250)}...`}
            </p>
            <button
              onClick={handleReadMore}
              className="text-blue-500 hover:underline mt-2"
            >
              {isExpanded ? "Read less" : "Read more"}
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AboutBuilder;
