import React from "react";
import Logo from "../../src/assets/images/logo.png"; // Path to your image file

const Footer = () => {
  return (
    <div>
      <footer>
        <div className="mx-auto max-w-screen-xl space-y-8 px-4 py-16 sm:px-6 lg:space-y-16 lg:px-8">
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
            <div>
              <div className="text-teal-600">
                <img src={Logo} width={100} height={40} alt="logo" />
              </div>

              <p className="mt-4 max-w-xs text-gray-500">
                Industrial Layout, Block 1A, 11 ,Wework Prestige Atlanta, 80
                feet main road, Koramangala, Bengaluru, Bengaluru Urban,
                Karnataka, 560034
              </p>

              <ul className="mt-8 flex gap-6">
                <li>
                  <a
                    href="https://www.facebook.com/homznoffiz/"
                    rel="noreferrer"
                    target="_blank"
                    className="text-gray-700 transition hover:opacity-75"
                  >
                    <span className="sr-only">Facebook</span>

                    <svg
                      className="h-6 w-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.instagram.com/homznoffiz/"
                    rel="noreferrer"
                    target="_blank"
                    className="text-gray-700 transition hover:opacity-75"
                  >
                    <span className="sr-only">Instagram</span>

                    <svg
                      className="h-6 w-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </li>

                <li>
                  <a
                    href="https://twitter.com/homznoffiz"
                    rel="noreferrer"
                    target="_blank"
                    className="text-gray-700 transition hover:opacity-75"
                  >
                    <span className="sr-only">Twitter</span>

                    <svg
                      className="h-6 w-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.linkedin.com/company/homznoffiz/"
                    rel="noreferrer"
                    target="_blank"
                    className="text-gray-700 transition hover:opacity-75"
                  >
                    <span className="sr-only">Linkedin</span>

                    <svg
                      className="h-6 w-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M20.47,2H3.53A1.45,1.45,0,0,0,2.06,3.43V20.57A1.45,1.45,0,0,0,3.53,22H20.47a1.45,1.45,0,0,0,1.47-1.43V3.43A1.45,1.45,0,0,0,20.47,2ZM8.09,18.74h-3v-9h3ZM6.59,8.48h0a1.56,1.56,0,1,1,0-3.12,1.57,1.57,0,1,1,0,3.12ZM18.91,18.74h-3V13.91c0-1.21-.43-2-1.52-2A1.65,1.65,0,0,0,12.85,13a2,2,0,0,0-.1.73v5h-3s0-8.18,0-9h3V11A3,3,0,0,1,15.46,9.5c2,0,3.45,1.29,3.45,4.06Z"></path>
                    </svg>
                  </a>
                </li>

                <li style={{ paddingTop: 5 }}>
                  <a
                    href="https://www.youtube.com/c/HomznoffizBestPropertyAdvisorinIndia"
                    rel="noreferrer"
                    target="_blank"
                    className="text-gray-700 transition hover:opacity-75"
                  >
                    <span className="sr-only">Youtube</span>
                    <svg
                      className="h-6 w-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M15.32 4.06c-.434-.772-.905-.914-1.864-.968C12.498 3.027 10.089 3 8.002 3c-2.091 0-4.501.027-5.458.091-.957.055-1.429.196-1.867.969C.23 4.831 0 6.159 0 8.497v.008c0 2.328.23 3.666.677 4.429.438.772.909.912 1.866.977.958.056 3.368.089 5.459.089 2.087 0 4.496-.033 5.455-.088.959-.065 1.43-.205 1.864-.977.451-.763.679-2.101.679-4.429v-.008c0-2.339-.228-3.667-.68-4.438zM6 11.5v-6l5 3-5 3z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>

            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:col-span-2 lg:grid-cols-4">
              <div>
                <p className="font-medium text-gray-900">Company</p>

                <ul className="mt-6 space-y-4 text-sm">
                  <li>
                    <a
                      href="/"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      {" "}
                      Our story{" "}
                    </a>
                  </li>

                  <li>
                    <a
                      href="/career"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      {" "}
                      Career{" "}
                    </a>
                  </li>

                  <li>
                    <a
                      href="/privacy-policy"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      Privacy Policy
                    </a>
                  </li>

                  <li>
                    <a
                      href="/terms-and-conditions"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      {" "}
                      Terms and Conditions
                    </a>
                  </li>
                  <li>
                    <a
                      href="/"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      {" "}
                      FAQs{" "}
                    </a>
                  </li>
                </ul>
              </div>

              <div>
                <p className="font-medium text-gray-900">Support and summary</p>

                <ul className="mt-6 space-y-4 text-sm">
                  <li>
                    <a
                      href="/privacy-policy"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      {" "}
                      Privacy & Policy{" "}
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.rentnresale.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      {" "}
                      Rent and Resale{" "}
                    </a>
                  </li>

                  <li>
                    <a
                      href="/"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      {" "}
                      Projects{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      href="/blogs"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      {" "}
                      Blogs{" "}
                    </a>
                  </li>
                </ul>
              </div>

              <div>
                <p className="font-medium text-gray-900">Our solutions</p>

                <ul className="mt-6 space-y-4 text-sm">
                  <li>
                    <a
                      href="https://apliqa.in"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      {" "}
                      Apliqa{" "}
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://bricksnpillars.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      Bricks N Pillers
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.dwelitedecor.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      Dwelite Decor
                    </a>
                  </li>
                  <li>
                    <a
                      href="/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      VR Now
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://tarry.in"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      Tarry
                    </a>
                  </li>
                </ul>
              </div>

              <div>
                <p className="font-medium text-gray-900">Our Servicess</p>

                <ul className="mt-6 space-y-4 text-sm">
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://play.google.com/store/apps/details?id=com.homznoffiz.agentnetwork"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      Agent Network
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.homznoffiz.agentmanager"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      Agent Manager
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.homznoffiz.agentbuilder"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      Agent Builder
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.homznoffiz.apliqa"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      Apliqa - Home service
                    </a>
                  </li>

                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://play.google.com/store/apps/details?id=com.homznoffiz.calledai"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      Called.ai
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <p className="text-xs text-gray-500">
            &copy; 2024. homznoffiz.com. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
