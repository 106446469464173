import React from "react";
 import Footer from "./Footer";
 const Layout = ({ children }) => {
  return (
    <div>
      
      <div style={{ padding: "20px",  }}>{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
