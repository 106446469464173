import { BASE_URL } from "../constant";
import AssetContext from "../context/AssetsContext/AssetContext";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AssetState = (propsx) => {
  const [assets, setAssets] = useState([]);
  const [totalAssets, setTotalAssets] = useState(0);

  const [assetsfeatured, setAssetsfeatured] = useState([]);
  const [totalAssetsFeatured, setTotalAssetsFeatured] = useState(0);

  const [asset, setAsset] = useState(null);

  const [topNavbarData, setTopNavbarData] = useState(null);

  const [amenities, setAmenities] = useState(null);
  const [builderDetails, setBuilderDetails] = useState(null);

  const [availableFilters, setAvailableFilters] = useState(null);

  const [reviews, setReviews] = useState([]);
  const [totalReviews, setTotalReviews] = useState(0);

  const getAvailableFilters = async (props) => {
    try {
      const response = await fetch(`${BASE_URL}/filter/getfilterx`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response.status === 200) {
        const json = await response.json();

        setAvailableFilters(json.data);
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const getSearchAssets = async (props) => {
    const {
      page,
      property_type,
      property_status,
      property_location,
      property_locality,
      property_builder,
      property_bhks,

      status,
      property_starting_price,
      property_maximum_price,
      property_name,
      is_featured,
      is_infocused,
    } = props;

    const limit = 10;
    const offset = page ? (page - 1) * limit : 0;

    const queryParams = {
      property_type: property_type,
      property_status: property_status,
      property_location: property_location,
      property_locality: property_locality,
      property_builder: property_builder,

      property_bhks: property_bhks,
      status: status,
      property_starting_price: property_starting_price,
      property_maximum_price: property_maximum_price,
      property_name: property_name,
      is_featured: is_featured,
      is_infocused: is_infocused,

      limit: limit,
      offset: offset,
    };

    // API Call

    try {
      const response = await fetch(
        `${BASE_URL}/asset/homznoffiz/get?${new URLSearchParams(queryParams)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        const parsedData = json.data.map((item) => ({
          ...item,

          property_bhks: JSON.parse(item.property_bhks),
          property_images: JSON.parse(item.property_images),
        }));

        if (json.data.length > 0) {
          setAssets(parsedData);

          setTotalAssets(json.total);

          // console.log(parsedData);
        } else {
          setAssets([]);
          setTotalAssets(0);
        }
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        //console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };
  
  const getAssetsFeatured = async (props) => {
    const {
      page,
      property_type,
      property_status,
      property_location,
      property_locality,
      property_builder,
      property_bhks,

      status,
      property_starting_price,
      property_maximum_price,
      property_name,
      is_featured,
      is_infocused,
    } = props;

    const limit = 10;
    const offset = page ? (page - 1) * limit : 0;

    const queryParams = {
      property_type: property_type,
      property_status: property_status,
      property_location: property_location,
      property_locality: property_locality,
      property_builder: property_builder,

      property_bhks: property_bhks,
      status: status,
      property_starting_price: property_starting_price,
      property_maximum_price: property_maximum_price,
      property_name: property_name,
      is_featured: is_featured,
      is_infocused: is_infocused,

      limit: limit,
      offset: offset,
    };

    // API Call

    try {
      const response = await fetch(
        `${BASE_URL}/asset/homznoffiz/get?${new URLSearchParams(queryParams)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        const parsedData = json.data.map((item) => ({
          ...item,

          property_bhks: JSON.parse(item.property_bhks),
          property_images: JSON.parse(item.property_images),
        }));

        if (json.data.length > 0) {
          setAssetsfeatured(parsedData);

          setTotalAssetsFeatured(json.total);

          // console.log(parsedData);
        } else {
          setAssetsfeatured([]);
          setTotalAssetsFeatured(0);
        }
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        //console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const getAAsset = async (props) => {
    const { slug } = props;

    // API Call

    try {
      const response = await fetch(`${BASE_URL}/asset/homznoffiz/getx/${slug}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response.status === 200) {
        const json = await response.json();
        const parsedData = json.data.map((item) => ({
          ...item,

          property_bhks: JSON.parse(item.property_bhks),
          property_images: JSON.parse(item.property_images),
          floor_plans: JSON.parse(item.floor_plans),
          aminities: JSON.parse(item.aminities),
          landmarks: JSON.parse(item.landmarks),
          faqs: JSON.parse(item.faqs),
        }));

        if (json.data.length > 0) {
          setAsset(parsedData[0]);
          // console.log(parsedData);
        }
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        //console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const getAssetAmenities = async (props) => {
    const { ids } = props;

    const queryParams = {
      ids: ids,
    };

    try {
      const response = await fetch(
        `${BASE_URL}/filter/homznoffiz/amenity/get?${new URLSearchParams(
          queryParams
        )}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        // const parsedData = json.data.map((item) => ({
        //   ...item,

        // }));

        if (json.data.length > 0) {
          const groupByCategory = (amenities) => {
            return amenities.reduce(
              (acc, amenity) => {
                const { category } = amenity;
                if (!acc[category]) {
                  acc[category] = [];
                }
                acc[category].push(amenity);
                if (!acc.list.includes(category)) {
                  acc.list.push(category);
                }
                return acc;
              },
              { list: [] }
            );
          };

          const groupedAmenities = groupByCategory(json.data);

          console.log(groupedAmenities);
          setAmenities(groupedAmenities);
        }
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        //console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const getAssetBuilderDetails = async (props) => {
    const { name } = props;

    try {
      const response = await fetch(
        `${BASE_URL}/filter/homznoffiz/builder/get/${name}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        const parsedData = json.data.map((item) => ({
          ...item,
          icon: JSON.parse(item.icon),
        }));

        if (json.data.length > 0) {
          setBuilderDetails(parsedData[0]);
        }
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        //console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const registerLead = async (props) => {
    const { name, phone, email, message } = props;

    try {
      const response = await fetch(`${BASE_URL}/lead/homznoffiz/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          name: name,
          phone: phone,
          email: email,
          message: message,
          m_b_id: 0,
          status: 1,
          lead_type: 2,
          follow_up_message: '{"data": null}',
          property_location: '{"data": null}',
          property_locality: '{"data": null}',
          property_status: '{"data": null}',
          property_type: '{"data": null}',
          follow_up_datetime: "",
          is_public: false,
        }),
      });

      if (response.status === 200) {
        toast.success("Your Enquiry request has been sent successfully");
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        toast.error("Couldn't Add lead, Try again later");
      }
    } catch (e) {
      toast.error("Couldn't Add lead, Try again later");
    }
  };

  const registerRequirement = async (props) => {
    const { name, phone, message } = props;

    try {
      const response = await fetch(
        `${BASE_URL}/requirement/homznoffiz/create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            uid: 0,
            name: name,
            phone: phone,
            message: message,
            status: 2,
            property_location: '{"data": null}',
            property_locality: '{"data": null}',
            property_status: '{"data": null}',
            property_type: '{"data": null}',
            starting_price: 1.1,
            maximum_price: 2.2,
          }),
        }
      );

      if (response.status === 200) {
        toast.success("Your Enquiry request has been sent successfully");
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        toast.error("Couldn't Add lead, Try again later");
      }
    } catch (e) {
      toast.error("Couldn't Add lead, Try again later");
    }
  };

  const createReview = async (props) => {
    const { uid, project_id, ratings, reviews } = props;

    try {
      console.log(`${BASE_URL}/reviews/homznoffiz/create`);
      const response = await fetch(`${BASE_URL}/reviews/homznoffiz/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          uid: uid,
          project_id: project_id,
          ratings: ratings,
          reviews: reviews,
        }),
      });

      if (response.status === 200) {
        getReviews({ pid: project_id, page: 1 });
        toast.success("Your review submitted successfully");
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        toast.error("Couldn't Add review, Try again later");
      }
    } catch (e) {
      toast.error("Couldn't Add review, Try again later");
    }
  };

  const getReviews = async (props) => {
    const { page, pid } = props;

    const limit = 6;
    const offset = page ? (page - 1) * limit : 0;

    const queryParams = {
      pid: pid,
      limit: limit,
      offset: offset,
    };

    // API Call

    try {
      const response = await fetch(
        `${BASE_URL}/reviews/homznoffiz/getall?${new URLSearchParams(
          queryParams
        )}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();

        if (json.data.length > 0) {
          setReviews(json.data);

          setTotalReviews(json.total);
        } else {
          setReviews([]);
          setTotalReviews(0);
        }
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        //console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  const getTopNavbarProjectData = async (props) => {

    const { city } = props;

     try {
      const response = await fetch(
        `${BASE_URL}/asset/homznoffiz/navbar/projectdata/get/${city}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();

        if (json.data!==null) {

          // const parsedData = json.data.map((item) => ({
          //   ...item,
          //   property_bhks: JSON.parse(item.property_bhks),
          //   property_images: JSON.parse(item.property_images),
          //   floor_plans: JSON.parse(item.floor_plans),
          //   aminities: JSON.parse(item.aminities),
          //   landmarks: JSON.parse(item.landmarks),
          //   faqs: JSON.parse(item.faqs),
          // }));

          setTopNavbarData(json.data);
          console.log(json.data);

        }
      } else if (response.status === 403) {
        toast.error("Your access has been revoked, Contact your Admin");
      } else {
        //console.log("FAILED WITH STATUS CODE " + response.status.toString());
      }
    } catch (e) {
      // console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

  return (
    <AssetContext.Provider
      value={{
        assets,
        totalAssets,
        getSearchAssets,

        assetsfeatured,
        totalAssetsFeatured,
        getAssetsFeatured,

        getAAsset,
        asset,

        getAvailableFilters,
        availableFilters,

        amenities,
        getAssetAmenities,

        builderDetails,
        getAssetBuilderDetails,

        registerLead,
        registerRequirement,

        getReviews,
        createReview,
        reviews,
        totalReviews,

        getTopNavbarProjectData,
        topNavbarData,
      }}
    >
      {propsx.children}
    </AssetContext.Provider>
  );
};
export default AssetState;
