import React, { useContext, useEffect } from "react";
import {   IMAGE_PATH } from "../constant";

 import BlogsContext from "../context/BlogsContext/BlogsContext";
import TimeAgo from "../components/TimeAgo"
const Blogs = () => {
  const context = useContext(BlogsContext);
  const { blogs, getBlogs } = context;

  useEffect(() => {
    getBlogs();
  }, []);

 

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 bg-transparent lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 bg-transparent">
          <h2 className="text-3xl bg-transparent font-bold tracking-tight text-gray-900 sm:text-4xl">
            From the blog
          </h2>
          <p className="mt-2 text-lg bg-transparent leading-8 text-gray-600">
            Learn how to grow your business with our expert advice.
          </p>
        </div>
        {blogs.length > 0 ? (
          <div className="mx-auto mt-10 grid bg-transparent max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {blogs.map((post) => (
              <article
                key={post.id}
                className="flex max-w-xl bg-transparent flex-col items-start justify-between"
              >
                <img
                    src={
                      IMAGE_PATH +
                      post.image.fieldname +
                      "/" +
                      post.image.filename
                    }
                   
                  style={{ borderRadius: 15 }}
                  alt="Conversion Rate"
                />

                <div className="group relative bg-transparent">
                  <h3 className="mt-3 text-lg font-semibold bg-transparent leading-6 text-gray-900 group-hover:text-gray-600">
                    <a href="/blogs" className="bg-transparent">
                      <span className="absolute inset-0 bg-transparent" />
                      {post.title}
                    </a>
                  </h3>
                  <p className="mt-5 line-clamp-3 text-sm bg-transparent leading-6 text-gray-600">
                    {post.description}
                  </p>
                </div>
                <div className="flex bg-transparent items-center gap-x-4 text-xs">
                <TimeAgo timestamp={post.updated_at} />

                  <div
                     className="relative z-10 bg-transparent rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                  >
                    {post.author}
                  </div>
                </div>
                {/* <div className="relative mt-8 flex items-center gap-x-4 bg-transparent">
                <img
                  src={post.author.imageUrl}
                  alt=""
                  className="h-10 w-10 rounded-full bg-gray-50 bg-transparent"
                />
                <div className="text-sm leading-6 bg-transparent">
                  <p className="font-semibold text-gray-900 bg-transparent">
                    <a href={post.author.href} className=" bg-transparent">
                      <span className="absolute inset-0 bg-transparent" />
                      {post.author.name}
                    </a>
                  </p>
                  <p className="text-gray-600 bg-transparent">
                    {post.author.role}
                  </p>
                </div>
              </div> */}
              </article>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default Blogs;
