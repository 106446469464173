import React, { useContext, useEffect } from "react";
import ReviewsDialogue from "./ReviewsDialogue";
import assetContext from "../../context/AssetsContext/AssetContext";
import DemoUser from "../../assets/images/userdemo.png";
const Reviews = ({ name, pid }) => {
  const context = useContext(assetContext);
  const { getReviews, reviews, totalReviews, createReview } = context;

  useEffect(() => {
    getReviews({ page: 1, pid: pid });
  }, [pid]);

  return (
    <div className="mt-10">
      <div className="border border-gray-300 rounded-md shadow-md bg-white py-4 mx-auto max-w-7xl px-6 lg:px-8">
        <div className="flex flex-row justify-between sm:items-center sm:flex-colum">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h1 className="text-2xl text-gray-800 font-semibold">
              {name} Reviews
            </h1>
            <div
              style={{
                width: "5vw",
                backgroundColor: "purple",
                height: 3,
                marginTop: 10,
              }}
            ></div>
            <div className="flex items-center mt-6">
              <svg
                className="w-4 h-4 text-yellow-300 me-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 22 20"
              >
                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
              </svg>
              <p className="ms-2 text-sm font-bold text-gray-900 dark:text-white">
                4.95
              </p>
              <span className="w-1 h-1 mx-1.5 bg-gray-500 rounded-full dark:bg-gray-400"></span>
              <a
                href="/"
                className="text-sm font-medium text-gray-900 underline hover:no-underline dark:text-white"
              >
                {totalReviews} reviews
              </a>
            </div>
          </div>
          <div className="mt-4 flex flex-col gap-4 sm:mt-0 sm:flex-row sm:items-center">
            <button
              className="inline-flex items-center justify-center gap-1.5 rounded-lg border border-gray-200 bg-white px-5 py-3 text-gray-500 transition hover:text-gray-700 focus:outline-none focus:ring"
              type="button"
            >
              <span className="text-sm font-medium">View All</span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                />
              </svg>
            </button>

            <ReviewsDialogue isShow={false} pid={pid} />

            {/* <button
              className="block rounded-lg bg-indigo-600 px-5 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring"
              type="button"
            >
              Write Review
            </button> */}
            
          </div>
        </div>

        {reviews.length > 0 ? (
          <div className="mx-auto  grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {reviews.map((post, index) => (
              <article
                key={index}
                className="flex max-w-xl flex-col items-start justify-between"
              >
                <div className="relative flex items-center gap-x-4">
                  <img
                    src={DemoUser}
                    alt=""
                    className="h-10 w-10 rounded-full bg-gray-50"
                  />
                  <div className="text-sm leading-6">
                    <p className="font-semibold text-gray-900">Unknown</p>
                    <p className="text-gray-600">{post.role}</p>
                  </div>
                </div>
                <div className="group relative mt-4">
                  {[...Array(5)].map((_, index) => (
                    <button
                      key={index}
                      type="button"
                      title={`Rate ${index + 1} stars`}
                      aria-label={`Rate ${index + 1} stars`}
                      // onClick={() => handleData("ratings", index + 1)} // Set the rating when clicked
                    >
                      {index < post.ratings ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="w-10 h-10 text-yellow-300"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="w-8 h-8 text-gray-600"
                        >
                          <polygon points="12 2 15 8 22 9 17 14 18 21 12 17 6 21 7 14 2 9 9 8 12 2"></polygon>
                        </svg>
                      )}
                    </button>
                  ))}
                  <p className="mt-5 line-clamp-6 text-sm leading-6 text-gray-600">
                    {post.reviews}
                  </p>
                  <p className="mt-3 line-clamp-6 text-sm leading-6 text-gray-600">
                    {post.created_at}
                  </p>
                </div>
              </article>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Reviews;
