import React, { useState, useEffect, useContext } from "react";
import { IoFilter } from "react-icons/io5";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import assetContext from "../../context/AssetsContext/AssetContext";

const FilterModal = () => {
  const [promise, setPromise] = useState(null);

  const [type, setType] = useState([]);
  const [status, setStatus] = useState([]);
  const [location, setLocation] = useState(localStorage.getItem("city") !== null ?localStorage.getItem("city"):"");
  const [builder, setBuilder] = useState([]);
  const [bhk, setBHK] = useState([]);
  const [p_status, setPStatus] = useState([]);
  // const [starting_price, setStartingPrice] = useState(0);
  const [maximum_price, setMaximumPrice] = useState(15);

  const st = [
    {
      id: 1,
      name: "Verified",
      val: 1,
      created_at: "2024-03-15T06:56:15.000Z",
      updated_at: "2024-03-15T06:56:15.000Z",
    },
    {
      id: 2,
      name: "Unverified",
      val: 0,
      created_at: "2024-03-15T06:56:15.000Z",
      updated_at: "2024-03-15T06:56:15.000Z",
    },
  ];

  // property_type: filters !== null ? filters.property_type : [],
  // property_status: filters !== null ? filters.property_status : [],
  // property_location: filters !== null ? filters.property_location : [],
  // property_builder: filters !== null ? filters.property_builder : [],
  // property_bhks: filters !== null ? filters.property_bhks : [],
  // status: filters !== null ? filters.status : [],
  // property_starting_price:
  //   filters !== null ? filters.property_starting_price : 0,
  // property_maximum_price:
  //   filters !== null ? filters.property_maximum_price : 10,

  const context = useContext(assetContext);
  const { getAvailableFilters, availableFilters } = context;

  const confirm = () =>
    new Promise((resolve, reject) => {
      setPromise({ resolve });
    });

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = () => {
    promise?.resolve({
      property_type: type,
      property_status: status,
      property_location: [location],
      property_builder: builder,
      property_bhks: bhk,
      status: p_status,
      property_maximum_price: maximum_price,
    });
    handleClose();
  };

  const handleType = (element) => {
    const arr = toggleElement(type, element);
    setType(arr);
  };

  const handleStatus = (element) => {
    const arr = toggleElement(status, element);
    setStatus(arr);
  };

  const handleLocation = (element) => {
    //const arr = toggleElement(location, element);
    setLocation(element);
    localStorage.setItem("city",element)
  };

  const handleBuilder = (element) => {
    const arr = toggleElement(builder, element);
    setBuilder(arr);
  };

  const handleBHK = (element) => {
    const arr = toggleElement(bhk, element);
    setBHK(arr);
  };

  const handlePStatus = (element) => {
    const arr = toggleElement(p_status, element);
    setPStatus(arr);
  };

  const handleChange = (event) => {
    setMaximumPrice(parseInt(event.target.value));
  };

  function toggleElement(array, element) {
    const index = array.indexOf(element);

    if (index === -1) {
      // Element is not present, so insert it
      array.push(element);
    } else {
      // Element is present, so remove it
      array.splice(index, 1);
    }

    return [...array]; // Make sure to return a new array to trigger re-render
  }

  useEffect(() => {
    getAvailableFilters();
    handleLocation(localStorage.getItem("city") !== null ?localStorage.getItem("city"):"");
     

  }, [localStorage.getItem("city")]);

  const ConfirmationDialog = () => (
    <Dialog open={promise !== null} onClose={handleClose} fullWidth>
      <DialogTitle style={{ fontWeight: "700" }}>Apply Filters</DialogTitle>
      <DialogContent>
        <div>
          <h6>Property Type</h6>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {availableFilters !== null &&
            availableFilters.property_type.length > 0
              ? availableFilters.property_type.map((item) => (
                  <div
                    key={item.id}
                    className={`${
                      type.includes(item.name)
                        ? "bg-indigo-800 text-white"
                        : "bg-white text-black"
                    }`}
                    style={{
                      borderRadius: 6,
                      fontSize: 12,
                      border: type.includes(item.name)
                        ? "0px solid #d8d0d0"
                        : ".5px solid #d8d0d0",
                      padding: "5px 15px",
                      margin: "5px 10px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleType(item.name)}
                  >
                    {item.name}
                  </div>
                ))
              : null}
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          <h6>Property Status</h6>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {availableFilters !== null &&
            availableFilters.property_status.length > 0
              ? availableFilters.property_status.map((item) => (
                  <div
                    key={item.id}
                    className={`${
                      status.includes(item.name)
                        ? "bg-indigo-800 text-white"
                        : "bg-white text-black"
                    }`}
                    style={{
                      borderRadius: 6,
                      fontSize: 12,

                      border: status.includes(item.name)
                        ? "0px solid #d8d0d0"
                        : ".5px solid #d8d0d0",
                      padding: "5px 15px",
                      margin: "5px 10px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleStatus(item.name)}
                  >
                    {item.name}
                  </div>
                ))
              : null}
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          <h6>Property Locations</h6>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {availableFilters !== null &&
            availableFilters.property_location.length > 0
              ? availableFilters.property_location.map((item) => (
                  <div
                    key={item.id}
                    className={`${
                      location===item.name
                        ? "bg-indigo-800 text-white"
                        : "bg-white text-black"
                    }`}
                    style={{
                      borderRadius: 6,
                      fontSize: 12,

                      border: location===item.name
                        ? "0px solid #d8d0d0"
                        : ".5px solid #d8d0d0",
                      padding: "5px 15px",
                      margin: "5px 10px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleLocation(item.name)}
                  >
                    {item.name}
                  </div>
                ))
              : null}
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          <h6>Property Builders</h6>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {availableFilters !== null &&
            availableFilters.property_builder.length > 0
              ? availableFilters.property_builder.map((item) => (
                  <div
                    key={item.id}
                    className={`${
                      builder.includes(item.name)
                        ? "bg-indigo-800 text-white"
                        : "bg-white text-black"
                    }`}
                    style={{
                      borderRadius: 6,
                      fontSize: 12,

                      border: builder.includes(item.name)
                        ? "0px solid #d8d0d0"
                        : ".5px solid #d8d0d0",
                      padding: "5px 15px",
                      margin: "5px 10px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleBuilder(item.name)}
                  >
                    {item.name}
                  </div>
                ))
              : null}
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          <h6>Property BHK</h6>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {availableFilters !== null &&
            availableFilters.property_bhk.length > 0
              ? availableFilters.property_bhk.map((item) => (
                  <div
                    key={item.id}
                    className={`${
                      bhk.includes(item.name)
                        ? "bg-indigo-800 text-white"
                        : "bg-white text-black"
                    }`}
                    style={{
                      borderRadius: 6,
                      fontSize: 12,

                      border: bhk.includes(item.name)
                        ? "0px solid #d8d0d0"
                        : ".5px solid #d8d0d0",
                      padding: "5px 15px",
                      margin: "5px 10px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleBHK(item.name)}
                  >
                    {item.name}
                  </div>
                ))
              : null}
          </div>
        </div>
      

        <div style={{ marginTop: 10 }}>
          <h6>Property Starting and Maximum Price</h6>

          <input
            type="range"
            min={0}
            max={15}
            value={maximum_price}
            onChange={handleChange}
            step={1}
            style={{
              width: "100%",
            }}
          />
          <p>Value: 0 to {maximum_price}</p>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} style={{ color: "red" }}>
          Cancel
        </Button>
        <Button onClick={handleConfirm}>Apply</Button>
      </DialogActions>
    </Dialog>
  );

  return [ConfirmationDialog, confirm];
};

export default FilterModal;
