import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAuth } from "../provider/AuthProvider";
import { ProtectedRoute } from "./ProtectedRoute";
// import LoginPage from "../components/Auth/LoginPage";
import LogoutPage from "../components/Auth/LogoutPage";
import Homepage from "../components/HomePage/Homepage";
import NotFoundPage from "../components/404NotFoundPage";
import Login1 from "../components/Auth/Login";
import Signup from "../components/Auth/Signup";
import Dashboard from "../components/Dashboard";
import PropertyList from "../components/Property/PropertyList";
import GalleryGrid from "../components/GalleryGrid";
import Career from "../components/Careers";
import PropertyDetails from "../components/Property/PropertyDetails";
import "../App.css";

import MyActivity from "../components/Account/MyActivity";
import PrivacyPolicy from "../components/Privacy/PrivacyPolicy";
import TermsOfUse from "../components/Privacy/Terms";
import Blogs from "../components/blogs";
const Routes = (props) => {
  
  const { token } = useAuth();

  // Define public routes accessible to all users
  
  const routesForPublic = [
    {
      path: "/service",
      element: <div>Service Page</div>,
    },
    {
      path: "/about-us",
      element: <div>About Us</div>,
    },
    {
      path: "/privacy-policy",
      element: <div><PrivacyPolicy/></div>,
    },
    {
      path: "/terms-and-conditions",
      element: <div><TermsOfUse/></div>,
    },
    {
      path: "/blogs",
      element: <div><Blogs/></div>,
    },
    {
      path: "*",
      element: (
        <div>
          <NotFoundPage />
        </div>
      ),
    },
  ];

  // Define routes accessible only to authenticated users

  const routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
      children: [
        {
          path: "/",
          element: <div> <Homepage/> </div>,
        },
        {
          path: "/user",
          element: <div>user home page</div>,
        },
        {
          path: "/user/profile",
          element: <div>user home page</div>,
        },
        {
          path: "/user/notification",
          element: <div>user home page</div>,
        },
        {
          path: "/user/chat",
          element: <div>user home page</div>,
        },
        {
          path: "/user/my-activity",
          element: <div><Dashboard page={MyActivity} /></div>,
        },
     
        {
          path: "/logout",
          element: <LogoutPage />,
        },
      ],
    },
  ];

  // Define routes accessible only to non-authenticated users
  
  const routesForNotAuthenticatedOnly = [
    {
      path: "/" ,
      element: <div> <Homepage/> </div>
    },
    {
      path: "/bengaluru-real-estate" ,
      element: <div> <Homepage/> </div>
    },
    {
      path: "/bhubaneswar-real-estate" ,
      element: <div> <Homepage/> </div>
    },
   
    { 
      path: "/:city/property/:slug",
      element: <div><PropertyDetails/></div>
    },   
    {
      path: "/gallery",
      element: <div><GalleryGrid/></div>,
    },
    {
      path: "/career",
      element: <div><Career/></div>,
    },
    {
      path: "/login",
      element: <Login1/>,
    },
    {
      path: "/signup",
      element: <Signup/>,
    },
    {
      path: "/:city/properties",
      element: <div><PropertyList/></div>,
    },
   
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...routesForPublic,
    ...(!token ? routesForNotAuthenticatedOnly : []),
    ...routesForAuthenticatedOnly,
  ]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default Routes;
