import { useRef, useState } from "react"

const FaqsCard = (props) => {

    const answerElRef = useRef()
    const [state, setState] = useState(false)
    const [answerH, setAnswerH] = useState('0px')
    const { faqsList, idx } = props

    const handleOpenAnswer = () => {
        const answerElH = answerElRef.current.childNodes[0].offsetHeight
        setState(!state)
        setAnswerH(`${answerElH + 20}px`)
    }

    return (
        <div 
            className="space-y-3 mt-5 overflow-hidden border-b"
            key={idx}
            onClick={handleOpenAnswer}
        >
            <h4 className="cursor-pointer pb-5 flex items-center justify-between text-lg text-gray-700 font-medium">
                {faqsList.q}
                {
                    state ? (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                        </svg>
                    )
                }
            </h4>
            <div
                ref={answerElRef} className="duration-300"
                style={state ? {height: answerH } : {height: '0px'}}
            >
                <div>
                    <p className="text-gray-500">
                        {faqsList.a}
                    </p>
                </div>
            </div>
        </div>
    )
}

const FAQs = () => {

    const faqsList = [
        {
            q: "What services do you provide as a real estate channel partner?",
            a: "As a real estate channel partner, we specialize in digital marketing for specific projects in the city. We facilitate closures for builders and earn commission from each booking."
        },
        {
            q: "What types of properties are listed on your website?",
            a: "Our website showcases listings for properties currently under construction and ready to move, offering a diverse range of options to suit every buyer's needs."
        },
        {
            q: "How can I benefit from using your services?",
            a: "By partnering with us, you gain access to exclusive projects and receive personalized assistance throughout the buying process. We streamline the journey from property search to closure, ensuring a seamless experience for our clients."
        },
        {
            q: "Are there any fees associated with using your platform?",
            a: " No, there are no fees for using our platform. We earn our commission from the builders upon successful closure, so there's no cost to you as a buyer."
        },
        {
            q: "How can I stay updated on new listings and market trends?",
            a: "Subscribe to our newsletter to receive regular updates on new listings, market trends, and special offers. Stay informed and make confident decisions with our valuable insights."
        },
        {
            q: "How do I get started with finding my dream property?",
            a: "Simply browse our listings, explore various projects, and contact us for personalized assistance. Our team is here to guide you every step of the way towards finding your perfect home."
        }
    ]
  
    return (
        <section className="leading-relaxed max-w-screen-xl mt-12 mx-auto px-4 md:px-8">
            <div className="space-y-3 text-center">
                <h1 className="text-3xl text-gray-800 font-semibold">
                    Frequently Asked Questions
                </h1>
                <p className="text-gray-600 max-w-lg mx-auto text-lg">
                    Answered all frequently asked questions, Still confused? feel free to contact us.
                </p>
            </div>
            <div className="mt-14 max-w-2xl mx-auto">
                {
                    faqsList.map((item, idx) => (
                        <FaqsCard
                        key={idx}
                            idx={idx}
                            faqsList={item}
                        />
                    ))
                }
            </div>
        </section>
    )
}

export default FAQs;