import { BsFillShieldLockFill, BsTelephoneFill } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import assetContext from "../../context/AssetsContext/AssetContext";

import OtpInput from "otp-input-react";
import { useState, useContext } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { auth } from "../../firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { toast, Toaster } from "react-hot-toast";

const LeadRegister2 = ({ isMargin }) => {
  const [data, setData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const context = useContext(assetContext);
  const { registerLead } = context;

  const handleClick = async (data) => {
    try {
      await registerLead({
        name: data.name,
        email: data.email,
        phone: data.phone,
        message: data.message,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleData = (key, value) => {
    setData((prevItem) => ({
      ...prevItem,
      [key]: value,
    }));
  };

  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {

      
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => {},
        },
        auth
      );
    }
  }

  function onSignup() {
    setLoading(true);
    onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;

    const formatPh = "+" + data.phone;

    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setShowOTP(true);
        toast.success("OTP has been sent the given phone!");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  function onOTPVerify() {
    setLoading(true);
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        console.log(res);
        setUser(res.user);
        setLoading(false);
        handleClick(data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("OTP invalid!");

        setLoading(false);
      });
  }

  return (
    <section
      style={{
        marginTop: isMargin ? 100 : 10,
        borderRadius: 6,
        width: "20vw",
      }}
      className="bg-gray-300 flex items-center justify-center hidden 2xl:block border border-gray-300 rounded-md shadow-md p-2"
    >
      <div>
        <Toaster toastOptions={{ duration: 4000 }} />
        <div
          id="recaptcha-container"
        //  className="bg-white h-[650px] sticky py-12 lg:flex lg:justify-center"
        ></div>
        {user ? (
          <div className="text-center text-black font-medium flex items-center justify-center text-2xl h-full">
            <label className="mt-80">
              your request has been forwarded. Our representative will be with
              you shortly
            </label>
          </div>
        ) : (
          <div className="flex items-center justify-center flex-col h-[650px] gap-4 rounded-lg">
            <div>
              <div className="text-gray-800 text-2xl font-semibold">
                Not able to find what you are looking for?
              </div>
              <p className="mt-3">
                Let us help you in your real-estate property search.
              </p>
            </div>
            <div>
              <label className="font-medium">Full name</label>
              <input
                type="text"
                onChange={(e) => handleData("name", e.target.value)}
                required
                placeholder="John Smith"
                className="w-full mt-2 py-2 text-gray-500 h-12"
                style={{ borderRadius: 4, borderColor: "gray" }}
              />
            </div>
            <div>
              <label className="font-medium">Your Email</label>
              <input
                type="email"
                onChange={(e) => handleData("email", e.target.value)}
                required
                placeholder="john@smith.com"
                className="w-full mt-2 py-2 text-gray-500 h-12"
                style={{ borderRadius: 4, borderColor: "gray" }}
              />
            </div>

            {showOTP ? (
              <>
                <div className="bg-white bg-indigo-700 w-fit mx-auto p-4 rounded-full">
                  <BsFillShieldLockFill size={30} />
                </div>
                <label
                  htmlFor="otp"
                  className="font-bold text-xl text-white text-center"
                >
                  Enter your OTP
                </label>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                  autoFocus
                  className="opt-container "
                ></OtpInput>
                <button
                  onClick={onOTPVerify}
                  className="bg-indigo-700 w-full flex gap-1 items-center justify-center py-2.5 text-white rounded"
                >
                  {loading && (
                    <CgSpinner size={20} className="mt-1 animate-spin" />
                  )}
                  <span>Verify OTP</span>
                </button>
              </>
            ) : (
              <div className="w-full px-2">
                <div>
                  <div className="flex justify-start align-start mb-2">
                    <label className="font-medium">Phone</label>
                  </div>

                  <PhoneInput
                    className=""
                    country={"in"}
                    value={data.value}
                    onChange={(e) => handleData("phone", e)}
                  />
                  <button
                    onClick={onSignup}
                    className="mt-4 bg-indigo-700 w-full flex gap-1 items-center justify-center py-2.5 text-white rounded"
                  >
                    {loading && (
                      <CgSpinner size={20} className="mt-1 animate-spin" />
                    )}
                    <span>Send code via SMS</span>
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default LeadRegister2;

// import React, { useContext, useState } from "react";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import assetContext from "../../context/AssetsContext/AssetContext";
// import OtpInput from "otp-input-react";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
// import { auth } from "../../firebase.config";
// import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
// import { CgSpinner } from "react-icons/cg";
// import { BsFillShieldLockFill, BsTelephoneFill } from "react-icons/bs";

// const LeadRegister2 = ({ isMargin }) => {
//   const context = useContext(assetContext);
//   if (!context) {
//     throw new Error("assetContext is not provided");
//   }
//   const { registerLead } = context;

//   const [otp, setOtp] = useState("");
//   const [showOTP, setShowOTP] = useState(false);
//   const [data, setData] = useState({
//     name: "",
//     phone: "",
//     email: "",
//     message: "",
//   });

//   const onCaptchVerify = () => {
//     if (!window.recaptchaVerifier) {
//       document.getElementById("recaptcha-container").innerHTML = "";
//       window.recaptchaVerifier = new RecaptchaVerifier(
//         "recaptcha-container",
//         {
//           "size": "invisible",
//           "callback": (response) => {
//             onSignup();
//           },
//           "expired-callback": () => {},
//         },
//         auth
//       );
//     }
//   };

//   const onSignup = () => {
//     try {
//       onCaptchVerify();

//       const appVerifier = window.recaptchaVerifier;
//       const formatPh = "+" + data.phone;

//        signInWithPhoneNumber(auth, formatPh, appVerifier)
//         .then((confirmationResult) => {
//           console.log(confirmationResult);
//           window.confirmationResult = confirmationResult;
//           setShowOTP(true);
//           toast.success("OTP has been sent to the given phone number!");
//         })
//         .catch((error) => {});
//     } catch (e) {
//       console.log(e);
//     }
//   };

//   const handleClick = async (data) => {
//     try {
//       await registerLead({
//         name: data.name,
//         email: data.email,
//         phone: data.phone,
//         message: data.message,
//       });
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const handleData = (key, value) => {
//     setData((prevItem) => ({
//       ...prevItem,
//       [key]: value,
//     }));
//   };

//   const onOTPVerify = () => {
//     window.confirmationResult
//       .confirm(otp)
//       .then(async (res) => {
//          await handleClick(data);
//       })
//       .catch((err) => {
//         console.log(err);
//         toast.error("OTP invalid!");
//       });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//   };

//   return (
//     <main
//       style={{
//         marginTop: isMargin ? 100 : 10,
//         borderRadius: 6,
//         width: "20vw",
//       }}
//       className="hidden 2xl:block border border-gray-300 rounded-md shadow-md p-2"
//     >
//       <ToastContainer position="top-right" />

//       <div
//         id="recaptcha-container"
//         className="bg-white h-[650px] sticky top-20 py-12 lg:flex lg:justify-center"
//       >
//         <div className="max-w-lg flex-1 mx-auto px-4 text-gray-600">
//           <div>
//             <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
//               Not able to find what you are looking for?
//             </h3>
//             <p className="mt-3">
//               Let us help you in your real-estate property search.
//             </p>
//           </div>
//           <div className="space-y-5 mt-4 lg:pb-12">
//             <div>
//               <label className="font-medium">Full name</label>
//               <input
//                 type="text"
//                 onChange={(e) => handleData("name", e.target.value)}
//                 required
//                 placeholder="John Smith"
//                 className="w-full mt-2 px-3 py-2 text-gray-500 h-12"
//                 style={{ borderRadius: 4, borderColor: "gray" }}
//               />
//             </div>
//             <div>
//               <label className="font-medium">Email</label>
//               <input
//                 type="email"
//                 onChange={(e) => handleData("email", e.target.value)}
//                 required
//                 placeholder="john@smith.com"
//                 style={{ borderRadius: 4, borderColor: "gray" }}
//                 className="w-full mt-2 px-3 py-2 text-gray-500 h-12"
//               />
//             </div>

//             <div className="  flex flex-col  rounded-lg">
//               {showOTP ? (
//                 <div>
//                   <label className="font-bold text-xl text-white text-center">
//                     Enter your OTP
//                   </label>
//                   <OtpInput
//                     value={otp}
//                     onChange={setOtp}
//                     OTPLength={6}
//                     otpType="number"
//                     disabled={false}
//                     autoFocus
//                     className="opt-container "
//                   ></OtpInput>
//                   <button
//                     onClick={onOTPVerify}
//                     className="bg-indigo-700 w-full flex gap-1 items-center justify-center py-2.5 text-white rounded"
//                   >
//                     <span>Verify OTP</span>
//                   </button>
//                 </div>
//               ) : (
//                 <div>
//                   <label className="font-medium">Phone</label>

//                   <PhoneInput
//                     country={"in"}
//                     value={data.phone}
//                     className="mt-2 mb-6"
//                     onChange={(e) => handleData("phone", e)}
//                   />

//                   <button
//                     onClick={onSignup}
//                     className="bg-indigo-700 w-full flex  items-center justify-center py-2.5 text-white rounded"
//                   >
//                     <span>Send code via SMS</span>
//                   </button>
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </main>
//   );
// };

// export default LeadRegister2;
