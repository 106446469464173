import * as Dialog from "@radix-ui/react-dialog";
import React, { useState, useEffect, useContext } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import assetContext from "../../context/AssetsContext/AssetContext";

const ReviewsDialogue = ({ isShow, pid }) => {
  const context = useContext(assetContext);
  const { createReview  } = context;

  const handleClick = async (data) => {
    // SetSpinnerStatus(true);

    // const errors = validateAssets(data);

    createReview({
      uid: 0,
      project_id: pid,
      ratings: data.ratings,
      reviews: data.reviews,
    });

    //  if (errors.length === 0) {
    //   toast.success("Validation successful!");

    //    addAssetx({ data });
    // } else {
    //   errors.map((error, index) => {
    //     toast.error(error);
    //   });
    //  }

    // SetSpinnerStatus(false);
  };

  const handleData = (key, value) => {
    setData((prevItem) => ({
      ...prevItem,
      [key]: value,
    }));
  };

  const [data, setData] = useState({
    ratings: 3,
    reviews: "",
  });

  return (
    <Dialog.Root className="fixed inset-0 z-1000 overflow-y-auto">
      <ToastContainer position="top-right" />

      <Dialog.Trigger
        className={`${
          isShow ? "2xl:block w-full mr-2" : ""
        } block rounded-lg bg-indigo-600 px-5 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring `}
      >
        Write Review
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 w-full h-full bg-black opacity-40" />
        <Dialog.Content className="fixed top-[55%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-lg mx-auto px-4">
          <div className="bg-white rounded-md shadow-lg px-4 py-6">
            <div className="flex items-center justify-end">
              <Dialog.Close className="p-2 text-gray-400 rounded-md hover:bg-gray-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 mx-auto"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </Dialog.Close>
            </div>
            <div className="max-w-sm mx-auto space-y-3 text-center ">
              <Dialog.Title className="text-xl font-medium text-gray-800 ">
                We Value Your Feedback!
              </Dialog.Title>

              <Dialog.Description className=" text-sm text-gray-600">
                <p>
                  Your experience matters to us. Please take a moment to let
                  people know more about the project. Thank you for your time!
                </p>
              </Dialog.Description>
              {/* <div className="relative">
                <input
                  onChange={(e) => handleData("name", e.target.value)}
                  className="w-full pl-12 pr-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                  placeholder="Enter your name"
                />
              </div> */}

              <div className="flex justify-center space-x-3">
                {[...Array(5)].map((_, index) => (
                  <button
                    key={index}
                    type="button"
                    title={`Rate ${index + 1} stars`}
                    aria-label={`Rate ${index + 1} stars`}
                    onClick={() => handleData("ratings", index + 1)} // Set the rating when clicked
                  >
                    {index < data.ratings ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-10 h-10 text-yellow-300"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-8 h-8 text-gray-600"
                      >
                        <polygon points="12 2 15 8 22 9 17 14 18 21 12 17 6 21 7 14 2 9 9 8 12 2"></polygon>
                      </svg>
                    )}
                  </button>
                ))}
              </div>
              <textarea
                rows="3"
                placeholder="Message..."
                onChange={(e) => handleData("reviews", e.target.value)}
                className="p-4 rounded-md w-full resize-none dark:text-gray-800 dark:bg-gray-50"
              ></textarea>

              <Dialog.Close asChild>
                <button
                  onClick={() => handleClick(data)}
                  className=" w-full mt-3 py-3 px-4 font-medium text-sm text-center text-white bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 rounded-lg ring-offset-2 ring-indigo-600 focus:ring-2"
                >
                  Submit
                </button>
              </Dialog.Close>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ReviewsDialogue;
