import { useEffect, useRef, useState } from "react";
import {
  IoPersonOutline,
  IoNotificationsOutline,
  IoChatboxOutline,
  IoLogOutOutline,
} from "react-icons/io5";
import { MdOutlineHistoryToggleOff } from "react-icons/md";
import { FaChildReaching } from "react-icons/fa6";

const Dashboard = (props) => {
  const { page } = props;
  const navigation = [
    {
      href: "/user/profile",
      name: "Profile",
      icon: <IoPersonOutline size={17} />,
    },
  
    {
      href: "/user/my-activity",
      name: "My Activity",
      icon: <MdOutlineHistoryToggleOff size={20} />,
    },
    {
      href: "/user/chat",
      name: "Chat",
      icon: <IoChatboxOutline size={17} />,
    },
    {
      href: "/user/notification",
      name: "Notification",
      icon: <IoNotificationsOutline size={17} />,
    },
  
  ];

  const navsFooter = [
    {
      href: "/user/logout",
      name: "Logout",
      icon: <IoLogOutOutline size={20} />,
    },
  ];

  const profileRef = useRef();

  const [isProfileActive, setIsProfileActive] = useState(false);

  useEffect(() => {
    const handleProfile = (e) => {
      if (profileRef.current && !profileRef.current.contains(e.target))
        setIsProfileActive(false);
    };
    document.addEventListener("click", handleProfile);
  }, []);

  return (
    <div
      style={{
        margin: "6vw 15vw",
        borderRadius: 5,
        border: "1px solid grey",
        // backgroundColor: "black",
        height: "70vh",
        display: "flex",
      }}
    >
      <nav className="top-0 left-0 w-full h-full border-r bg-white space-y-8 sm:w-80">
        <div class="flex flex-col h-full px-4">
          <div className="h-20 flex items-center pl-2">
            <div className="w-full flex items-center gap-x-4">
              <img
                alt=""
                src="https://randomuser.me/api/portraits/women/79.jpg"
                className="w-10 h-10 rounded-full"
              />
              <div>
                <span className="block text-gray-700 text-sm font-semibold">
                  Alivika tony
                </span>
                <span className="block mt-px text-gray-600 text-xs">
                  Hobby Plan
                </span>
              </div>
            </div>
          </div>
          <div className="overflow-auto">
            <ul className="text-sm font-medium flex-1">
              {navigation.map((item, idx) => (
                <li key={idx}>
                  <a
                    href={item.href}
                    className="flex items-center gap-x-2 text-gray-600 p-2 rounded-lg  hover:bg-gray-50 active:bg-gray-100 duration-150"
                  >
                    <div className="text-gray-500">{item.icon}</div>
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
            <div className="pt-2 mt-2 border-t">
              <ul className="text-sm font-medium">
                {navsFooter.map((item, idx) => (
                  <li key={idx}>
                    <a
                      href={item.href}
                      className="flex items-center gap-x-2 text-gray-600 p-2 rounded-lg  hover:bg-gray-50 active:bg-gray-100 duration-150"
                    >
                      <div className="text-gray-500">{item.icon}</div>
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </nav>
      {page}
    </div>
  );
};

export default Dashboard;
