import React from "react";

import PaintingService from "../../src/assets/icons/painting-services.png"; // Path to your image file
import RentalFurniture from "../../src/assets/icons/rental-furniture.png"; // Path to your image file
import PackersAndMovers from "../../src/assets/icons/packers-movers.png"; // Path to your image file
import PropertInspection from "../../src/assets/icons/property-inspection.png"; // Path to your image file
import VastuConsultation from "../../src/assets/icons/vastu-consultation.png"; // Path to your image file
import SolarRooftop from "../../src/assets/icons/solar-rooftop.png"; // Path to your image file
import LeadRegister3 from "./Property/LeadRegister3";
const PartnerServicess = () => {

  const features = [
    {
      icon: (
        <img
          style={{ width: 60 }}
          alt="Error"
          src={PaintingService}
          className="bg-transparent"
        />
      ),
      title: "Painting Service",
    },
    {
      icon: (
        <img
        
          style={{ width: 60 }}
          src={RentalFurniture}
          alt="Error"

          className="bg-transparent"
        />
      ),
      title: "Rental Furniture",
    },
    {
      icon: (
        <img
          style={{ width: 60 }}
          src={PackersAndMovers}
          alt="Error"

          className="bg-transparent"
        />
      ),
      title: "Packers and Movers",
    },
    {
      icon: (
        <img
          style={{ width: 60 }}
          src={PropertInspection}
          alt="Error"

          className="bg-transparent"
        />
      ),
      title: "Property Inspection",
    },
    {
      icon: (
        <img
          style={{ width: 60 }}
          src={VastuConsultation}
          alt="Error"

          className="bg-transparent"
        />
      ),
      title: "Vastu Consultation",
    },
    {
      icon: (
        <img
          style={{ width: 60 }}
          src={SolarRooftop}
          alt="Error"

          className="bg-transparent"
        />
      ),
      title: "Solar Rooftop",
    },
  ];

  return (
    <section className="relative py-28 bg-gray-900">
      <div className="bg-transparent relative z-10 max-w-screen-xl mx-auto px-4 text-gray-300 justify-between gap-24 lg:flex md:px-8">
        <div className="bg-transparent max-w-xl">
          <h3 className="bg-transparent text-white text-3xl font-semibold sm:text-4xl">
            Our partner services
          </h3>
          <p className="bg-transparent mt-3">
            Unlocking Real Estate Potential: Elevate Your Experience with Our
            Exclusive Partner Services
          </p>
        </div>
        <div className="bg-transparent mt-12 lg:mt-0">
            
          <ul className="bg-transparent grid gap-8 sm:grid-cols-2">
            {features.map((item, idx) => (
              <div key={idx} className="bg-transparent" >
                <li key={idx} className="bg-transparent flex items-center justify-space gap-x-4">
                  <div className="bg-transparent flex-none w-12 h-12   text-cyan-400 rounded-lg flex items-center justify-center">
                    {item.icon}
                  </div>
                  <LeadRegister3 isPartneredService={true} title={item.title} />


                  
                </li>
              </div>
            ))}
          </ul>
        </div>
      </div>
      <div
        className="absolute inset-0 max-w-md mx-auto h-72 blur-[118px]"
        style={{
          background:
            "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)",
        }}
      ></div>
    </section>
  );
};
export default PartnerServicess;
