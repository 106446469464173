// Modal.js
import React from "react";

const ChooseCityModal = ({ show, onClose, onSelect }) => {
  if (!show) {
    return null;
  }

  return (
    <div className=" fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
      <div className="bg-white p-6 rounded shadow-lg">
        <h2 className="text-xl mb-4">Select a City</h2>
        <button
          className="bg-white text-black py-2 px-4 rounded mr-2 border border-black hover:bg-black hover:text-white"
          onClick={() => onSelect("Bengaluru")}
        >
          Bengaluru
        </button>
        <button
          className="bg-white text-black py-2 px-4 rounded mr-2 border border-black hover:bg-black hover:text-white"
          onClick={() => onSelect("Bhubaneswar")}
        >
          Bhubaneswar
        </button>
         
      </div>
    </div>
  );
};

export default ChooseCityModal;
