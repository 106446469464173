import React, { useContext, useState, useEffect } from "react";
import * as Tabs from "@radix-ui/react-tabs";
import PropertyCard from "./PropertyCard";
import TopNavBar from "../NavBar";
import Footer from "../Footer";
import LeadRegister2 from "./LeadRegister2";
import PropertyFAQ from "./PropertyFAQ";
import SimilarProjects from "./SimilarProjects";
import Reviews from "./Reviews";
import Gallery from "./Gallery";
import Overview from "./Overview";
import RERA from "./RERA";
import MasterPlan from "./MasterPlan";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SpinnerDotted } from "spinners-react";

import FloorPlan from "./FloorPlan";
import PriceList from "./PriceList";
import Amenity from "./Amenity";
import LocationMap from "./LocationMap";
import AboutBuilder from "./AboutBuilder";
import HomeLoanCalculator from "./HomeloanCalculator";
import PropertyRequirement from "./PropertyRequirement";
import { useParams } from "react-router-dom";
import assetContext from "../../context/AssetsContext/AssetContext";

const PropertyDetails = () => {
  const [spinnerStatus, SetSpinnerStatus] = useState(false);

  const { slug } = useParams();
  const context = useContext(assetContext);
  const { asset, getAAsset } = context;

  const [projects, setProjects] = useState([]);

  // const [project, setProject] = useState(null);

  useEffect(() => {
    const projectData = localStorage.getItem("projectData");
    if (projectData) {
      setProjects(JSON.parse(projectData));
      //localStorage.removeItem('projectData'); // Optionally remove it after retrieving
    }
  }, []);

  const tabItems = [
    "Overview",
    "Regulatory Information",
    "Gallery",
    "Master plan",
    "Price and floor plan",
    "Amenities",
    "Builders",
    "Location map",
    "Reviews",
    "Similar projects",
    "FAQs",
    "Home Loan Calculator",
  ];

  useEffect(() => {
    getAAsset({ slug: slug });
   }, []);

  const handleTabClick = (id) => {
    const element = document.getElementById(id);
    const offsetTop = element.offsetTop - 280;
    window.scrollTo({
      top: offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <div className="flex flex-col justify-center items-center max-w-full bg-[#F7F7F7] mt-16 p-4">
      <div className="fixed top-0 w-full z-50 bg-white">
        <TopNavBar />
      </div>

      {asset !== null ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="max-w-screen-2xl   mt-20">
            <div className="flex justify-center mb-4">
              <div className="sm:w-90vw md:w-80vw lg:w-70vw xl:w-60vw 2xl:w-50vw mr-4">
                <PropertyCard
                  isColumn={true}
                  isShowDetails={false}
                  isShowDesc={false}
                  project={asset}
                  similarProjects={projects}
                />
              </div>
              <LeadRegister2 isMargin={false} />
            </div>
          </div>
        </div>
      ) : null}

      <div className="sticky top-20 z-30">
        <Tabs.Root
          defaultValue="Overview"
          className="max-w-screen-2xl mx-auto px-4 md:px-8 bg-white border border-gray-300 rounded-md shadow-md bg-white py-4"
        >
          <Tabs.List
            className="w-full flex flex-wrap items-center gap-x-3 overflow-x-auto text-sm sticky top-16 bg-white z-30 border-b justify-start xl:justify-center 2xl:justify-center"
            aria-label="Manage your account"
          >
            {tabItems.map((item, idx) => (
              <Tabs.Trigger
                key={idx}
                className="group outline-none py-1.5 border-b-2 border-transparent text-gray-500 data-[state=active]:border-indigo-600 data-[state=active]:text-indigo-600"
                value={item}
                onClick={() =>
                  handleTabClick(item.replace(/\s+/g, "-").toLowerCase())
                }
              >
                <div className="py-1.5 px-3 rounded-lg duration-150 group-hover:text-indigo-600 group-hover:bg-gray-50 group-active:bg-gray-100 font-medium">
                  {item}
                </div>
              </Tabs.Trigger>
            ))}
          </Tabs.List>
          {/* {tabItems.map((item, idx) => (
            <Tabs.Content key={idx} className="py-6" value={item}>
              <p className="text-xs leading-normal">
                This is <b>{item}</b> Tab
              </p>
            </Tabs.Content>
          ))} */}
        </Tabs.Root>
      </div>

      <div id="overview" className="w-full flex justify-center items-center  ">
        <Overview
          name={asset !== null ? asset.property_name : ""}
          text={asset !== null ? asset.description : ""}
        />
      </div>
      <div
        id="regulatory-information"
        className="w-full flex justify-center items-center  "
      >
        <RERA
          name={asset !== null ? asset.property_name : ""}
          pRERA={asset !== null ? asset.project_rera : ""}
          hRERA={asset !== null ? asset.homznoffiz_rera : ""}
        />
      </div>

      <div id="gallery" className="w-full flex justify-center items-center  ">
        <Gallery
          name={asset !== null ? asset.property_name : ""}
          brochurURL={asset !== null ? asset.brochure : ""}
          videoURL={asset !== null ? asset.property_video : ""}
          images={
            asset !== null &&
            asset.property_images !== null &&
            asset.property_images.images.length > 0
              ? asset.property_images.images
              : []
          }
        />
      </div>

      <div
        id="master-plan"
        className="w-full flex justify-center items-center  "
      >
        <MasterPlan
          name={asset !== null ? asset.property_name : ""}
          url={asset !== null ? asset.master_plan : ""}
        />
      </div>

      <div
        id="price-and-floor-plan"
        className="w-full flex flex-col justify-center items-center  "
      >
        <FloorPlan
          name={asset !== null ? asset.property_name : ""}
          type={asset !== null ? asset.property_type : ""}
          floorPlans={
            asset !== null &&
            asset.floor_plans !== null &&
            asset.floor_plans.plans.length > 0
              ? asset.floor_plans.plans
              : []
          }
        />
        <PriceList
          name={asset !== null ? asset.property_name : ""}
          type={asset !== null ? asset.property_type : ""}
          floorPlans={
            asset !== null &&
            asset.floor_plans !== null &&
            asset.floor_plans.plans.length > 0
              ? asset.floor_plans.plans
              : []
          }
        />
      </div>
      <div id="amenities" className="w-full flex justify-center items-center  ">
        <Amenity
          name={asset !== null ? asset.property_name : ""}
          idsx={
            asset !== null &&
            asset.aminities !== null &&
            asset.aminities.aminities.length > 0
              ? asset.aminities.aminities
              : []
          }
        />
      </div>
      <div id="builders" className="w-full flex justify-center items-center  ">
        <AboutBuilder
          name={asset !== null ? asset.property_name : ""}
          property_builder={asset !== null ? asset.property_builder : ""}
        />
      </div>
      <div
        id="location-map"
        className="w-full flex justify-center items-center  "
      >
        <LocationMap
          name={asset !== null ? asset.property_name : ""}
          latitude={asset !== null ? asset.latitude : 12.9763173}
          longitude={asset !== null ? asset.longitude : 77.5745222} //bengaluru lon
          landmarks={
            asset !== null &&
            asset.landmarks !== null &&
            asset.landmarks.landmarks.length > 0
              ? asset.landmarks.landmarks
              : []
          }
        />
      </div>

      <div id="reviews" className="w-full flex justify-center items-center  ">
        <Reviews 
        name={asset !== null ? asset.property_name : ""} 
        pid={asset !== null ? asset.id : 0} 

        />
      </div>
      <div
        id="similar-projects"
        className="w-full flex justify-center items-center  "
      >
        <SimilarProjects SimilarProjects={projects} />
      </div>
      <div id="faqs" className="w-full flex justify-center items-center  ">
        <PropertyFAQ
          name={asset !== null ? asset.property_name : ""}
          faqs={
            asset !== null && asset.faqs !== null && asset.faqs.faqs.length > 0
              ? asset.faqs.faqs
              : []
          }
        />
      </div>
      <div
        id="home-loan-calculator"
        className="w-full flex flex-col justify-center items-center  "
      >
        <HomeLoanCalculator />
        <PropertyRequirement />
        {/* <TopCities/> */}
      </div>

      <Footer />
    </div>
  );
};

export default PropertyDetails;
