import { BASE_URL } from "../constant";
import GalleryContext from "../context/GalleryContext/GalleryContext";
import { useState } from "react";
 import "react-toastify/dist/ReactToastify.css";

const GalleryState = (propsx) => {

  const [achievement, setAchievement] = useState([]);
  const [gallery, setGallery] = useState([]);
 
  const getAchievement = async (props) => {
    try {
      const response = await fetch(`${BASE_URL}/media/Homznoffiz/Achievement?type=all`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
       
        },
      });

      if (response.status === 200) {
        const json = await response.json();

        setAchievement(json.filteredFiles);

       } else if (response.status === 403) {
       } else {
       }
    } catch (e) {
       console.log(e.message);
      console.log("SERVER DOWN");
    }
  };
  const getGallery = async (props) => {
    try {
      const response = await fetch(`${BASE_URL}/media/Homznoffiz/Gallery?type=all`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
       
        },
      });

      if (response.status === 200) {
        const json = await response.json();

        setGallery(json.filteredFiles);

       } else if (response.status === 403) {
       } else {
       }
    } catch (e) {
       console.log(e.message);
      console.log("SERVER DOWN");
    }
  };

 
 

  return (
    <GalleryContext.Provider
      value={{
      
        achievement,
        getAchievement,

        getGallery,
        gallery,
  
      }}
    >
      {propsx.children}
    </GalleryContext.Provider>
  );
};
export default GalleryState;
