import { useEffect, useContext, useState } from "react";
import Logo from "../../src/assets/images/logo.png"; // Path to your image file
import ProfileDropdown from "./ProfileDropdown";
import Notification from "./Notification";
import assetContext from "../context/AssetsContext/AssetContext";

import { useNavigate } from "react-router-dom";

const TopNavBar = () => {
  const [selectedCity, setSelectedCity] = useState("");
  // const [projects, setProjects] = useState([]);
  const context = useContext(assetContext);
  const { topNavbarData, getTopNavbarProjectData, getAAsset } = context;

  useEffect(() => {
    setSelectedCity(
      localStorage.getItem("city") !== null ? localStorage.getItem("city") : ""
    );
  }, []);

  useEffect(() => {
    getTopNavbarProjectData({ city: selectedCity });
  }, [selectedCity]);

  const [state, setState] = useState(false);

  const [drapdownState, setDrapdownState] = useState({
    isActive: false,
    idx: null,
  });
  const navigate = useNavigate();

  const cities = [
    {
      label: "All Cities",
      navs: [
        {
          // title: "Analytics",
          id: 1,
          name: "Bengaluru",
        },
        {
          //title: "Reports",
          id: 2,
          name: "Bhubaneswar",
        },
      ],
    },
  ];

  const getPropertyValue = (obj, property) => {
    return obj?.[property] ?? null;
  };

  const HandleNavigate = ( slug) => {
    getAAsset({ slug: slug });
    window.open(
      `/${localStorage.getItem("city")}/property/${slug}`,
      "_blank"
    );
  };

  const handleFilters = async (obj) => {
    const queryParams = new URLSearchParams({
      page: 1,
      property_type:
        getPropertyValue(obj, "property_type") !== null
          ? [getPropertyValue(obj, "property_type")]
          : [],
      property_status:
        getPropertyValue(obj, "property_status") !== null
          ? [getPropertyValue(obj, "property_status")]
          : [],

      property_location:
        localStorage.getItem("city") !== null
          ? [localStorage.getItem("city")]
          : [],
      property_locality:
        getPropertyValue(obj, "property_locality") !== null
          ? [getPropertyValue(obj, "property_locality")]
          : [],
      property_builder:
        getPropertyValue(obj, "property_builder") !== null
          ? [getPropertyValue(obj, "property_builder")]
          : [],
      property_bhks:
        getPropertyValue(obj, "property_bhks") !== null
          ? [getPropertyValue(obj, "property_bhks")]
          : [],
      status: [],
      property_starting_price: 0,
      property_maximum_price:
        getPropertyValue(obj, "property_maximum_price") !== null
          ? getPropertyValue(obj, "property_maximum_price")
          : 15,
      property_name: "",
      is_featured: 0,
      is_infocused: 0,
    }).toString();

    window.open(
      `/${localStorage.getItem("city")}/properties?${queryParams}`,
      "_blank"
    );
    // navigate(`/${localStorage.getItem("city")}/properties?${queryParams}`);
  };

  const buy = [
    {
      label: "Residential Properties",
      navs: [
        {
          // title: "Analytics",
          name: `Plots on sale in ${selectedCity}`,
          namex: `Plot`,
          id: 1,
        },
        {
          // title: "Analytics",
          name: `Apartments on sale in ${selectedCity}`,
          namex: `Apartment`,
          id: 2,
        },
        {
          // title: "Analytics",
          name: `Villas on sale in ${selectedCity}`,
          namex: `Villa`,
          id: 3,
        },
        {
          // title: "Analytics",
          name: `Villament on sale in ${selectedCity}`,
          namex: `Villament`,
          id: 4,
        },
        {
          // title: "Analytics",
          name: `Farm houses on sale in ${selectedCity}`,
          namex: `Farm house`,
          id: 5,
        },
        {
          // title: "Analytics",
          name: `Penthouse on sale in ${selectedCity}`,
          namex: `Penthouse`,
          id: 6,
        },
        {
          // title: "Analytics",
          name: `Row house on sale in ${selectedCity}`,
          namex: `Row house`,
          id: 7,
        },
      ],
    },
    {
      label: "Commercial Properties",
      navs: [
        {
          // title: "Analytics",
          name: `Lands on sale in ${selectedCity}`,
          namex: `Land`,
          id: 1,
        },
        {
          // title: "Analytics",
          name: `Office Space on sale in ${selectedCity}`,
          namex: `Office Space`,
          id: 2,
        },
        {
          // title: "Analytics",
          name: `Showroom on sale in ${selectedCity}`,
          namex: `Showroom`,
          id: 3,
        },
        {
          // title: "Analytics",
          name: `Shop on sale in ${selectedCity}`,
          namex: `Shop`,
          id: 4,
        },
        {
          // title: "Analytics",
          name: `Warehouse on sale in ${selectedCity}`,
          namex: `Warehouse`,
          id: 5,
        },
        {
          // title: "Analytics",
          name: `Coworking space on sale in ${selectedCity}`,
          namex: `Coworking Space`,
          id: 6,
        },
      ],
    },
    {
      label: "Configuration of Rooms",
      navs: [
        {
          // title: "Analytics",
          name: `1BHK on sale in ${selectedCity}`,
          namex: `1BHK`,
          id: 1,
        },
        {
          // title: "Analytics",
          name: `2BHK on sale in ${selectedCity}`,
          namex: `2BHK`,
          id: 2,
        },
        {
          // title: "Analytics",
          name: `3BHK on sale in ${selectedCity}`,
          namex: `3BHK`,
          id: 3,
        },
        {
          // title: "Analytics",
          name: `4BHK on sale in ${selectedCity}`,
          namex: `4BHK`,
          id: 4,
        },
        {
          // title: "Analytics",
          name: `5BHK on sale in ${selectedCity}`,
          namex: `5BHK`,
          id: 5,
        },
        {
          // title: "Analytics",
          name: `6BHK on sale in ${selectedCity}`,
          namex: `6BHK`,
          id: 6,
        },
        {
          // title: "Analytics",
          name: `Studio Apartment on sale in ${selectedCity}`,
          namex: `Studio Apartment`,
          id: 7,
        },
      ],
    },
    {
      label: "Budget friendly",
      navs: [
        {
          // title: "Analytics",
          name: `Property under 25 lakhs on sale in ${selectedCity}`,
          namex: 0.25,
          id: 1,
        },
        {
          // title: "Analytics",
          name: `Property under 50 lakhs on sale in ${selectedCity}`,
          namex: 0.5,
          id: 2,
        },
        {
          // title: "Analytics",
          name: `Property under 75 lakhs on sale in ${selectedCity}`,
          namex: 0.75,
          id: 3,
        },
        {
          // title: "Analytics",
          name: `Property under 1 crore on sale in ${selectedCity}`,
          namex: 1.0,
          id: 4,
        },
        {
          // title: "Analytics",
          name: `Property under 2 crore on sale in ${selectedCity}`,
          namex: 2.0,
          id: 5,
        },
        {
          // title: "Analytics",
          name: `Property above 2 crore on sale in ${selectedCity}`,
          namex: 20,
          id: 6,
        },
      ],
    },
  ];

  const projects = [
    {
      label: "Projects in Trending Locality",
      navs: topNavbarData != null ? topNavbarData.localities : [],
    },

    {
      label: "Top Searched Projects",
      navs: topNavbarData != null ? topNavbarData.most_searched : [],
    },

    {
      label: "Newly Launched Projects",
      navs: topNavbarData != null ? topNavbarData.new_launch : [],
    },

    {
      label: "Top Projects by Builders",
      navs: topNavbarData != null ? topNavbarData.top_builder : [],
    },
  ];

  const servicess = [
    {
      label: "For Buyers",
      navs: [
        {
          // title: "Analytics",
          name: "Home Loan",
          path: "/",
        },
        {
          // title: "Analytics",
          name: "Property Legal Services",
          path: "/",
        },
        {
          // title: "Analytics",
          name: "Real Estate Services",
          path: "/",
        },
        {
          // title: "Analytics",
          name: "Escrow",
          path: "/",
        },
        {
          // title: "Analytics",
          name: "Vastu Consultation",
          path: "/",
        },
        {
          // title: "Analytics",
          name: "Property Inspection",
          path: "/",
        },
        {
          // title: "Analytics",
          name: "Check your Credit Score",
          path: "/",
        },
        {
          // title: "Analytics",
          name: "NRI Guide",
          path: "/",
        },
      ],
    },

    {
      label: "For Tenants",
      navs: [
        {
          // title: "Analytics",
          name: "Online Rent Agreement",
          path: "/",
        },
        {
          // title: "Analytics",
          name: "Rent Receipt Generator",
          path: "/",
        },
        {
          // title: "Analytics",
          name: "Packers & Movers",
          path: "/",
        },
        {
          // title: "Analytics",
          name: "Furniture on Rent",
          path: "/",
        },
        {
          // title: "Analytics",
          name: "Home Appliances on Rent",
          path: "/",
        },
        {
          // title: "Analytics",
          name: "Tenant Guide",
          path: "/",
        },
      ],
    },

    {
      label: "For Owners",
      navs: [
        {
          // title: "Analytics",
          name: "Sell/Rent Property Online",
          path: "/",
        },
        {
          // title: "Analytics",
          name: "Property Management",
          path: "/",
        },
        {
          // title: "Analytics",
          desc: "Home Interior Designers",
          path: "/",
        },
        {
          // title: "Analytics",
          name: "Home Painting Services",
          path: "/",
        },
        {
          // title: "Analytics",
          name: "Solar Rooftop",
          path: "/",
        },
        {
          // title: "Analytics",
          name: "Seller Guide",
          path: "/",
        },
      ],
    },
    {
      label: "For Agents",
      navs: [
        {
          // title: "Analytics",
          name: "Agent Network",
          path: "/",
        },
        {
          // title: "Analytics",
          desc: "Agent Manager",
          path: "/",
        },
        {
          // title: "Analytics",
          name: "Agent Builder",
          path: "/",
        },
        {
          // title: "Analytics",
          name: "Called.ai",
          path: "/",
        },
      ],
    },
  ];

  // Replace / paths with your paths

  const navigation = [
    {
      title: "City",
      path: "/",
      isDrapdown: true,
      navs: cities,
    },
    { title: "Buy", path: "/", isDrapdown: true, navs: buy },
    // { title: "Rent", path: "/", isDrapdown: true, navs: buy },
    {
      title: "Projects",
      path: "/",
      isDrapdown: true,
      navs: projects,
    },
    {
      title: "Services",
      path: "/",
      isDrapdown: true,
      navs: servicess,
    },
    // { title: "Resources", path: "/", isDrapdown: false },
    { title: "Blogs", path: "/blogs", isDrapdown: false },

    // {
    //   title: "Features",
    //   path: "/",
    //   isDrapdown: true,
    //   navs: dropdownNavs,
    // },
  ];

  const handleBuy = ( title, dropdownItem_label, value) => {
    // console.log(id);
    // console.log(title);
    // console.log(dropdownItem_label);
    // console.log(value);

    if (title === "Buy" && dropdownItem_label === "Residential Properties") {
      handleFilters({ property_type: value });
    } else if (
      title === "Buy" &&
      dropdownItem_label === "Commercial Properties"
    ) {
      handleFilters({ property_type: value });
    } else if (
      title === "Buy" &&
      dropdownItem_label === "Configuration of Rooms"
    ) {
      handleFilters({ property_bhks: value });
    } else if (title === "Buy" && dropdownItem_label === "Budget friendly") {
      handleFilters({ property_maximum_price: value });
    } else {
    }
  };

  const handleClick = (id, title, dropdownItem_label,slug, value) => {
    console.log(id);
    console.log(title);
    console.log(dropdownItem_label);
    console.log(value);

    if (title === "City") {
      localStorage.setItem("city", value);

      navigate(`/`);
      window.location.reload();
    } else if (
      title === "Projects" &&
      dropdownItem_label === "Projects in Trending Locality"
    ) {
      handleFilters({ property_locality: value });
    } else if (
      title === "Projects" &&
      dropdownItem_label === "Top Searched Projects"
    ) {
      HandleNavigate(slug);
      //console.log(value);
    } else if (
      title === "Projects" &&
      dropdownItem_label === "Newly Launched Projects"
    ) {
      HandleNavigate(slug);
      //console.log(value);
    } else if (
      title === "Projects" &&
      dropdownItem_label === "Top Projects by Builders"
    ) {
      handleFilters({ property_builder: value });
    }
  };

  useEffect(() => {
    document.onclick = (e) => {
      const target = e.target;
      if (!target.closest(".nav-menu"))
        setDrapdownState({ isActive: false, idx: null });
    };
  }, []);

  return (
    <>
      <nav
        className={`relative bg-white z-20 bg-grey bg-white w-full md:static md:text-sm md:border-none ${
          state ? "shadow-lg rounded-b-xl md:shadow-none" : ""
        }`}
      >
        <div className="items-center gap-x-14 px-4   mx-auto md:flex md:px-8">
          <div className="flex items-center justify-between py-3 md:py-5 md:block">
            <a href="/">
              <img src={Logo} width={100} height={40} alt="logo" />
            </a>
            <div className="md:hidden">
              <button
                className="text-gray-500 hover:text-gray-800"
                onClick={() => setState(!state)}
              >
                {state ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm8.25 5.25a.75.75 0 01.75-.75h8.25a.75.75 0 010 1.5H12a.75.75 0 01-.75-.75z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>

          <div
            className={`nav-menu flex-1 pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
              state ? "block" : "hidden"
            }`}
          >
            <ul className="items-center space-y-6 md:flex md:space-x-6 md:space-y-0">
              {navigation.map((item, idx) => {
                return (
                  <li key={idx}>
                    {item.isDrapdown ? (
                      <button
                        className="w-full flex items-center justify-between gap-1 text-gray-700 hover:text-indigo-600"
                        onClick={() =>
                          setDrapdownState({
                            idx,
                            isActive: !drapdownState.isActive,
                          })
                        }
                      >
                        {item.title}
                        {drapdownState.idx === idx && drapdownState.isActive ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              fillRule="evenodd"
                              d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
                              clipRule="evenodd"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                              clipRule="evenodd"
                            />
                          </svg>
                        )}
                      </button>
                    ) : (
                      <a
                        href={item.path}
                        className="block text-gray-700 hover:text-indigo-600"
                      >
                        {item.title}
                      </a>
                    )}
                    {item.isDrapdown &&
                    drapdownState.idx === idx &&
                    drapdownState.isActive ? (
                      <div className="mt-6 inset-x-0 top-20 w-full bg-white z-50 md:absolute md:border-y md:shadow-md md:mt-0">
                        <ul className="max-w-screen-xl mx-auto grid   items-start gap-6 md:p-8 md:grid-cols-2 lg:grid-cols-4">
                          {item?.navs.map((dropdownItem, idx) => (
                            <li key={idx}>
                              {/* <p className="text-indigo-600 text-sm">
                                {dropdownItem.label}
                              </p> */}

                              <span className="text-gray-800 duration-200 group-hover:text-indigo-600 text-sm font-medium md:text-base">
                                {dropdownItem.label}
                              </span>

                              <ul className="mt-5 space-y-4">
                                {dropdownItem.navs.map((navItem, idx) => (
                                  <li
                                    onClick={() =>
                                      item.title === "Buy"
                                        ? handleBuy(
                                             item.title,
                                            dropdownItem.label,
                                            navItem.namex
                                          )
                                        : handleClick(
                                            navItem.id,
                                            item.title,
                                            dropdownItem.label,
                                            navItem.slug,
                                            navItem.name
                                          )
                                    }
                                    key={idx}
                                    className="group"
                                  >
                                    <div
                                      // href={navItem.path}
                                      className="flex gap-3 items-center"
                                    >
                                      {/* <div className="w-12 h-12 rounded-full bg-indigo-50 text-indigo-600 flex items-center justify-center duration-150 group-hover:bg-indigo-600 group-hover:text-white md:w-14 md:h-14">
                                        {navItem.icon}
                                      </div> */}
                                      <div>
                                        {/* <span className="text-gray-800 duration-200 group-hover:text-indigo-600 text-sm font-medium md:text-base">
                                          {navItem.title}
                                        </span> */}
                                        <p className="text-sm text-gray-600 group-hover:text-gray-800  hover:cursor-default">
                                          {dropdownItem.label ===
                                          "Projects in Trending Locality"
                                            ? `Property on sale in ${navItem.name}`
                                            : navItem.name}
                                        </p>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}
                  </li>
                );
              })}

              <div className="flex-1 items-center justify-end gap-x-6 space-y-3 md:flex md:space-y-0">
                {/* <li className="hidden lg:block">
                  <a
                    href="/login"
                    className="block py-3 text-center text-gray-700 hover:text-indigo-600 border rounded-lg md:border-none"
                  >
                    Log in
                  </a>
                </li> */}
                <li>
                  <a
                    href="/signup"
                    className="block  py-3 px-4 font-medium text-center text-white bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 active:shadow-none rounded-lg shadow md:inline"
                  >
                    Sign in
                  </a>
                </li>
                {/* <li className="hidden lg:block">
                  <Notification />
                </li> */}
                {/* <li className="hidden lg:block">
                  <ProfileDropdown />
                </li> */}
              </div>
            </ul>
          </div>
        </div>
      </nav>
      {state ? (
        <div
          className="z-10 fixed top-0 w-screen h-screen bg-black/20 backdrop-blur-sm md:hidden"
          onClick={() => setState(false)}
        ></div>
      ) : (
        ""
      )}
    </>
  );
};

export default TopNavBar;
