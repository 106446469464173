import React, { useState, useContext, useEffect } from "react";
import Home from "../../assets/icons/home.png";
import Plan from "../../assets/icons/plan.png";
import { useNavigate } from "react-router-dom";
import { IMAGE_PATH } from "../../constant";

import { MdContentCopy } from "react-icons/md";

import { MdFavoriteBorder } from "react-icons/md";
import LeadRegister from "./LeadRegister";

const PropertyCard = (props) => {
  const { isColumn, isShowDetails, isShowDesc, project, similarProjects } =
    props;

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const navigate = useNavigate();

  const HandleClick = (slug) => {
    window.open(
      `/${localStorage.getItem("city")}/property/${slug}`,
      "_blank"
    );
    //   navigate(`/${localStorage.getItem("city")}/property/${id}/${name}`);
    localStorage.setItem("projectData", JSON.stringify(similarProjects));
  };

  return (
    <div
      style={{
        margin: "5px 0px",
        backgroundColor: "white",
        padding: 10,
        borderRadius: 5,
      }}
      className="border border-gray-300 rounded-md shadow-md p-2"
    >
      <div
        className={`${
          isColumn
            ? "flex flex-col items-center bg-white  w-full md:w-75 space-y-4 md:space-y-0"
            : "flex flex-col md:flex-row lg:flex-row xl:flex-row items-center bg-white  w-full  space-y-4 md:space-y-0"
        }`}
      >
        <div
          onClick={() => HandleClick( project.slug)}
          style={
            {
              //backgroundColor:"black",
              //width:""
            }
          }
          className={`${isColumn ? "w-full p-4" : "w-full md:w-25vw  p-4"}`}
        >
          <img
            src={IMAGE_PATH + project.cover_image}
            alt=""
            style={{ height: isColumn ? 400 : 250, borderRadius: 10 }}
            className="w-full object-cover"
          />
        </div>

        <div
          style={{
            //  backgroundColor: "black",
            padding: "0px 10px",
            display: "flex",
            width: "100%",
            height: 250,
            // fontFamily:"sans-serif",
            //maxWidth:"40vw",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                backgroundColor: "white",
                justifyContent: "space-between",
              }}
            >
              <label
                onClick={() => HandleClick( project.slug)}
                style={{
                  backgroundColor: "white",
                  fontSize: 16,
                  fontWeight: "450",
                }}
              >
                {project.property_name}
              </label>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "white",
                }}
              >
                <MdFavoriteBorder
                  style={{
                    margin: "0px 10px",
                    backgroundColor: "white",
                  }}
                  size="24px"
                />

                <MdContentCopy
                  style={{
                    margin: "0px 10px",
                    backgroundColor: "white",
                  }}
                  size="22px"
                />
              </div>
            </div>
            <label
              style={{
                backgroundColor: "white",
                fontSize: 12,
                marginTop: 5,
              }}
            >
              {project.property_locality}, {project.property_location}
            </label>
          </div>
          <label
            style={{
              backgroundColor: "white",
              fontSize: 14,
              fontWeight: 500,
              marginTop: 5,
            }}
          >
            {project.property_status}
          </label>
          <label
            style={{
              backgroundColor: "white",
              fontSize: 20,
              marginTop: 5,
              fontWeight: 500,
            }}
          >
            ₹ {project.property_starting_price} -{" "}
            {project.property_maximum_price} Cr
          </label>
          <div
            style={{
              borderRadius: 10,
              marginTop: 10,
              backgroundColor: "white",
              padding: "5px 10px",
              border: "1px solid #d9dbda",
            }}
          >
            <div
              style={{
                borderRadius: 10,
                border: "0.5px solid white",
                backgroundColor: "white",
                display: "flex",
                justifyContent: "start",
                width: "100%",
              }}
            >
              <div
                style={{
                  borderRadius: 18,
                  width: 50,
                  padding: "5px 0px",
                  backgroundColor: "white",
                }}
              >
                <img
                  style={{
                    padding: 10,
                    borderRadius: 15,
                    backgroundColor: "#E8F4FF",
                  }}
                  src={Home}
                  alt="PropertyPic"
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  padding: "5px 10px",
                  backgroundColor: "white",
                  flexDirection: "column",
                  justifyContent: "start",
                }}
              >
                <label
                  style={{
                    fontSize: 14,
                    marginTop: 5,
                    backgroundColor: "white",
                    color: "grey",
                    fontWeight: "500",
                  }}
                >
                  Project Size
                </label>
                <label
                  style={{
                    fontSize: 14,
                    fontWeight: "400",
                    backgroundColor: "white",
                  }}
                >
                  {project.property_units} units || {project.property_size}{" "}
                  Acres
                </label>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                height: 1,
                backgroundColor: "#d9dbda",
              }}
            ></div>
            <div
              style={{
                borderRadius: 10,
                border: "0.5px solid white",
                backgroundColor: "white",
                display: "flex",
                justifyContent: "start",
                width: "100%",
              }}
            >
              <div
                style={{
                  borderRadius: 18,
                  width: 50,
                  padding: "5px 0px",
                  backgroundColor: "white",
                }}
              >
                <img
                  style={{
                    padding: 10,
                    borderRadius: 15,
                    backgroundColor: "#E8F4FF",
                  }}
                  src={Plan}
                  alt="PropertyPic"
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  padding: "5px 10px",
                  backgroundColor: "white",
                  flexDirection: "column",
                  justifyContent: "start",
                }}
              >
                <label
                  style={{
                    fontSize: 14,
                    marginTop: 5,
                    backgroundColor: "white",
                    color: "grey",
                    fontWeight: "500",
                  }}
                >
                  Project Configuration
                </label>
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: "400",
                    backgroundColor: "white",
                    display: "flex",
                  }}
                >
                  {project.property_bhks.bhks.length > 0
                    ? project.property_bhks.bhks.map((itemx, index) => (
                        <div key={itemx.name} style={{ marginRight: 3 }}>
                          {itemx.name + ","}
                        </div>
                      ))
                    : null}{" "}
                  || {project.property_type}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isShowDesc ? (
        <div>
          <p
            style={{
              backgroundColor: "white",
              fontSize: 13,
              paddingTop: 10,
              // maxWidth: "50vw"
            }}
            className="sm:w-full xl:w-50vw"
          >
            {isExpanded && project.description !== null
              ? project.description
              : project.description === null
              ? ""
              : `${project.description.substring(0, 160)}...`}
            <span
              onClick={toggleReadMore}
              style={{ color: "blue", cursor: "pointer" }}
            >
              {isExpanded ? " Show Less" : " Read More"}
            </span>
          </p>
        </div>
      ) : null}

      <div
        style={{
          alignItems: "end",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <LeadRegister isShow={false} title="Enquiry Now"   />
        {isShowDetails ? (
          <button
            onClick={() => HandleClick( project.slug)}
            className="w-32 py-2 ml-2 shadow-sm rounded-md bg-indigo-700 text-white mt-4 flex items-center justify-center"
          >
            Show details
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default PropertyCard;
