import React, { useContext, useEffect } from "react";
import assetContext from "../../context/AssetsContext/AssetContext";
import { IMAGE_PATH } from "../../constant";

const Amenity = ({ name, idsx }) => {
 
 
  const context = useContext(assetContext);
  const { amenities, getAssetAmenities } = context;
  const parseIcon = (iconString) => {
    try {
      return JSON.parse(iconString);
    } catch (error) {
      console.error("Error parsing icon JSON:", error);
      return null;
    }
  };

  useEffect(() => {
     getAssetAmenities({ ids: idsx });
  }, [idsx]);

  return (
    <div className=" mt-10 py-14 sm:py-22 px-8 relative w-full max-w-7xl  bg-cover border border-gray-300 rounded-md shadow-md bg-white py-4">
      <div className="mb-6 flex max-w-7xl flex-row justify-between sm:items-center sm:flex-colum">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h1 className="text-2xl text-gray-800 font-semibold">
            {name} Amenity
          </h1>
          <div
            style={{
              width: "5vw",
              backgroundColor: "purple",
              height: 3,
              marginTop: 10,
            }}
          ></div>
        </div>
      </div>

      {amenities !== null
        ? amenities.list.map((item, index) => (
            <div key={index}>
              {index!==0?(<hr className=" border-t-1 border-gray-300" />):null}
              
              <div className="flex w-full p-4">
                {/* First div: 30% width of the parent */}
                <div className="w-15vw p-4">
                  <div className="font-semibold text-gray-900">{item}</div>
                </div>

                {/* Second div: Grid parent with 2 columns */}

                <div className="w-45vw p-2 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  gap-1">
                  {/* First grid item */}
                  {amenities[item].map((item, index) => {
                    const parsedIcon = parseIcon(item.icon);

                    console.log(parseIcon.fieldname);
                    return (
                      <div key={index} className="flex items-center space-x-2 p-2   rounded-md">
                        {parsedIcon && (
                          <img
                            src={
                              IMAGE_PATH +
                              parsedIcon.fieldname +
                              "/" +
                              parsedIcon.filename
                            }
                            alt={item.name}
                            className="w-6"
                          />
                        )}

                        <p className="text-sm font-medium text-gray-500 ">
                          {item.name}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ))
        : null}
    </div>
  );
};

export default Amenity;
