import React from 'react'
import TabHorizontal from '../TabHorizontal'
const MyActivity = ()=> {
  return (
    <div>
        <TabHorizontal/>
    </div>
  )
}

export default MyActivity;