import React, { useState, useContext, useEffect } from "react";
import BackGroundImage from "../../../src/assets/images/slidex.png"; // Path to your image file

import Layout from "../Layout";
import Testimonials from "../testimonials";
import Statistics from "../Stats";
import NewsLetters from "../Newsletters";
import Blogs from "../blogs";
import GalleryList from "../GalleryList";
import ChooseCityModal from "../ChooseCity";

import Builders from "../FeaturedBuilders";
import FAQs from "../FAQs";
import CategoryCard from "../CategoryCard";

import ProjectTypesCard from "../ProjectTypesCard";
import InHouseServicess from "../InhouseService";
import PartnerServicess from "../PartneredService";
import TopNavBar from "../NavBar";
import SearchBarWithDropdown from "../Property/DropdownStates";

import WhyUs from "../WhyUs";
import SlickList from "./SlickList";
import SlickList3 from "./SlickList3";
import { useNavigate } from "react-router-dom";
const Homepage = () => {
  const [type, setType] = useState([]);
  const [status, setStatus] = useState([]);
  const [query, setQuery] = useState("");

  const navigate = useNavigate();

  const handleType = (element) => {
    const arr = toggleElement(type, element);
    setType(arr);
  };
  const handleStatus = (element) => {
    const arr = toggleElement(status, element);
    setStatus(arr);
  };

  function toggleElement(array, element) {
    const index = array.indexOf(element);

    if (index === -1) {
      // Element is not present, so insert it
      array.push(element);
    } else {
      // Element is present, so remove it
      array.splice(index, 1);
    }

    return [...array]; // Make sure to return a new array to trigger re-render
  }

  const [val, setVal] = useState(0);

  const haldleChange = (val) => {
    setVal(val);
  };

  const handleDataReceived = async (textx) => {
    setQuery("" + textx.text);

    await handleFilters();
  };

  const handleFilters = async () => {
    const queryParams = new URLSearchParams({
      page: 1,
      property_type: type !== null ? type : [],
      property_status: status !== null ? status : [],
      property_locality:  [],

      property_location:
        localStorage.getItem("city") !== null
          ? [localStorage.getItem("city")]
          : [],
      property_builder: [],
      property_bhks: [],
      status: [],
      property_starting_price: 0,
      property_maximum_price: 15,
      property_name: query,
      is_featured: 0,
      is_infocused: 0,
    }).toString();
    navigate(`/${localStorage.getItem("city")}/properties?${queryParams}`);
  };

  const [showModal, setShowModal] = useState(false);

  const handleSelectCity = (selectedCity) => {
    localStorage.setItem("city", selectedCity);
    window.location.reload();

    setShowModal(false);
  };

  useEffect(() => {
    const city = localStorage.getItem("city");
    if (city === null || city === "") {
      setShowModal(true);
    } else {
      navigate(`/${localStorage.getItem("city").toLowerCase()}-real-estate`);
    }
  }, [localStorage.getItem("city")]);

  return (
    <>
      <div>
        <div className="fixed top-0 bg-white w-full z-50">
          <TopNavBar />
        </div>

        <Layout>
          <div style={{ marginTop: 60 }}>
            <div
              style={{
                width: "100%",
                height: 650,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",

                backgroundImage: `url(${BackGroundImage})`, // Use template literals
              }}
            >
              <div
                style={{
                  position: "relative",
                  //  width: "60vw", 
                  height: 300,  
                  backgroundColor: "transparent",
                }}
                className="w-90vw sm:w-80vw md:w-60vw mx-6"
              >
                <div
                  style={{
                    position: "absolute",
                    width: "100%", 
                    background:
                      "rgba(255, 255, 255, 0.1)" /* Semi-transparent white */,
                    backdropFilter: "blur(10px)" /* Blur effect */,
                    WebkitBackdropFilter: "blur(10px)" /* For Safari support */,
                    border:
                      "1px solid rgba(255, 255, 255, 0.3)" /* Optional border */,
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                    //  padding: "20px" /* Internal padding */,
                    color: "#ffffff" /* Text color */,
                    // "rgba(100, 89, 101, 0.8)" /* Color #645965 with 50% opacity */,

                    //  backgroundColor: "red", // Just for visibility
                  }}
                  className="md:p-[20px] mt-52 h-100vw md-60vw"
                >
                  <SearchBarWithDropdown
                    onDataReceived={handleDataReceived}
                    style={{ width: "100%", backgroundColor: "black" }}
                  />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      gap: 5,
                      alignContent: "space-between",
                      marginBottom: 20,
                      //backgroundColor:"black"
                    }}
                  >
                    <button
                      onClick={() => handleType("Apartment")}
                      className={`${
                        type.includes("Apartment") ? "bg-white text-black" : ""
                      } mr-3 px-3 py-1.5 text-sm  duration-100 border rounded-lg hover:border-indigo-600 active:shadow-lg`}
                    >
                      Apartment
                    </button>

                    <button
                      onClick={() => handleType("Villa")}
                      className={`${
                        type.includes("Villa") ? "bg-white text-black" : ""
                      } mr-3 px-3 py-1.5 text-sm  duration-100 border rounded-lg hover:border-indigo-600 active:shadow-lg`}
                    >
                      Villa
                    </button>
                    <button
                      onClick={() => handleType("Plot")}
                      className={`${
                        type.includes("Plot") ? "bg-white text-black" : ""
                      } mr-3 px-3 py-1.5 text-sm  duration-100 border rounded-lg hover:border-indigo-600 active:shadow-lg`}
                    >
                      Plot
                    </button>
                    <button
                      onClick={() => handleStatus("Ready to Move")}
                      className={`${
                        status.includes("Ready to Move")
                          ? "bg-white text-black"
                          : ""
                      } mr-3 px-3 py-1.5 text-sm  duration-100 border rounded-lg hover:border-indigo-600 active:shadow-lg`}
                    >
                      Ready to Move
                    </button>

                    <button
                      onClick={() => handleStatus("New Launch")}
                      className={`${
                        status.includes("New Launch")
                          ? "bg-white text-black"
                          : ""
                      } mr-3 px-3 py-1.5 text-sm  duration-100 border rounded-lg hover:border-indigo-600 active:shadow-lg`}
                    >
                      New Launch
                    </button>

                    <button
                      onClick={() => handleStatus("Under Construction")}
                      className={`${
                        status.includes("Under Construction")
                          ? "bg-white text-black"
                          : ""
                      } mr-3 px-3 py-1.5 text-sm  duration-100 border rounded-lg hover:border-indigo-600 active:shadow-lg`}
                    >
                      Under Construction
                    </button>
                    {/* <button
                      onClick={() => handleStatus("Upcoming")}
                      className={`${
                        status.includes("Upcoming") ? "bg-white text-black" : ""
                      } mr-3 px-3 py-1.5 text-sm  duration-100 border rounded-lg hover:border-indigo-600 active:shadow-lg`}
                    >
                      Upcoming
                    </button> */}
                  </div>
                  {/* <div>{totalAssets}</div> */}
                </div>

                <div
                  style={{
                    //  position: "absolute",
                    //  margin: "0px 20vw",
                    //  width: "30%",

                    height: "22%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    backgroundColor: "white", // Just for visibility
                  }}
                  className="mt-36 sm:w-50vw md:w-30vw lg:w-20vw xl:15vw 2xl:15vw "
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                  >
                    {["Buy", "Rent", "Sell"].map((item, idx) => (
                      <div
                        key={idx}
                        onClick={() => haldleChange(idx)}
                        style={{
                          borderRadius: 5,
                          // display: "flex",
                          // flexDirection: "column",
                          alignItems: "center",
                          padding: "2px 25px",

                          // backgroundColor: val === idx ? "black" : "white",
                          // minWidth: 50, // Set a minimum width for each item to prevent them from collapsing
                        }}
                        className={`${
                          val === idx ? "bg-indigo-800" : "bg-white"
                        }`}
                      >
                        <a
                          href={idx !== 0 ? "https://www.rentnresale.com" : "/"}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            backgroundColor: "transparent",
                            fontSize: 15,
                            textAlign: "center",
                            fontWeight: "450",
                            color: val === idx ? "white" : "black",

                            //  fontFamily: "sans-serif",
                          }}
                        >
                          {item}
                        </a>
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  style={{
                    //height: "10%",
                    width:"100%",
                    position: "absolute",

                    bottom: "100%",
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    fontSize: 35,
                    color: "white",
                    fontWeight: 600,
                    

                   //backgroundColor: "black", // Just for visibility
                  }}
                  //className="mt-16 sm:w-50vw md:w-30vw lg:w-20vw xl:15vw 2xl:15vw "
                >
                  <div 
                  // className="w-[100%] flex justify-center items-center"
                  >
                    <h1 className="text-3xl sm:text-3xl md:text-3xl lg:text-4xl xl:text-5xl px-20 font-bold text-center text-black">
                    YOUR PROPERTY
                    </h1>
                    <h1 className="text-3xl sm:text-3xl md:text-3xl lg:text-4xl xl:text-5xl px-20 font-bold text-center text-black">
                    OUR RESPONSIBILITY
                    </h1>
                    {/* <p className="mt-4 text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl text-gray-700 text-center">
                    Enjoy upscale living with panoramic cityscape views
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
            <ProjectTypesCard />
            <CategoryCard />
            {/* <ProjectInFocus/> */}
            <SlickList />
            <SlickList3 />
            {/* <HorizontalList/>  */}
            <InHouseServicess />
            <PartnerServicess />
            <WhyUs />
            <Builders />
            <Statistics />
            <Testimonials />
            <GalleryList />
            <NewsLetters />
            <Blogs />
            <FAQs />
            {/* <Career/> */}
          </div>
          <ChooseCityModal
            show={showModal}
            onClose={() => setShowModal(false)}
            onSelect={handleSelectCity}
          />
        </Layout>
      </div>
    </>
  );
};

export default Homepage;
