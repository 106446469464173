import React, { useContext } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import assetContext from "../../context/AssetsContext/AssetContext";
import { IMAGE_PATH } from "../../constant";
import { useNavigate } from "react-router-dom";

const SimilarProjects = ({ SimilarProjects }) => {
  const navigate = useNavigate();

  const context = useContext(assetContext);
  const { getAAsset } = context;

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          color: "white",
          backgroundColor: "grey",
          borderRadius: 40,
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          color: "white",
          backgroundColor: "grey",
          borderRadius: 40,
        }}
        onClick={onClick}
      />
    );
  }
  const settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          //  dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      //   {
      //     breakpoint: 480,
      //     settings: {
      //       slidesToShow: 1,
      //       slidesToScroll: 1,
      //     },
      //   },
    ],
  };

  const HandleClick = (id, slug) => {
    getAAsset({ slug: slug });
    window.open(
      `/${localStorage.getItem("city")}/property/${slug}`,
      "_blank"
    );
    // newTab.addEventListener("load", () => {
    //   newTab.postMessage(JSON.stringify(SimilarProjects), "*");
    // });
    localStorage.setItem("projectData", JSON.stringify(SimilarProjects));

    // navigate(`/${localStorage.getItem("city")}/property/${id}/${name}`);
  };

  return (
    <div className=" mt-10 py-14 sm:py-22 px-8 relative w-full max-w-7xl  bg-cover border border-gray-300 rounded-md shadow-md bg-white py-4">
      <div className="mb-6 flex max-w-7xl flex-row justify-between sm:items-center sm:flex-colum">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h1 className="text-2xl text-gray-800 font-semibold">
            Similar Project in Focus
          </h1>
          <div
            style={{
              width: "5vw",
              backgroundColor: "purple",
              height: 3,
              marginTop: 10,
            }}
          ></div>
        </div>
      </div>

      {SimilarProjects.length > 0 ? (
        <div className="">
          <Slider {...settings}>
            {SimilarProjects.map((project, index) => (
              <div
                onClick={() => HandleClick(project.id, project.slug)}
                key={index}
                className="border border-gray-300 rounded-md shadow-md bg-white"
              >
                <div className="w-full p-2  border-b border-gray-300">
                  <img
                    src={IMAGE_PATH + project.cover_image}
                    alt=""
                    style={{ height: 250 }}
                    className="w-full object-cover rounded-md"
                  />
                </div>

                <div className="p-2 bg-black-700">
                  <h1
                    style={{
                      color: "black",
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  >
                    {project.property_name}
                  </h1>
                  <h1
                    style={{
                      marginTop: 5,
                      fontSize: 15,
                      color: "grey",

                      backgroundColor: "transparent",
                    }}
                  >
                    {project.property_locality}, {project.property_location}
                  </h1>

                  <h3
                    style={{
                      marginTop: 10,
                      color: "black",
                      fontWeight: "bold",
                      fontSize: 16,
                      backgroundColor: "transparent",
                    }}
                  >
                    {" "}
                    ₹ {project.property_starting_price} -{" "}
                    {project.property_maximum_price} Cr
                  </h3>
                  <div
                    style={{
                      display: "flex",
                      marginTop: 5,
                      marginBottom: 20,
                      backgroundColor: "transparent",
                      justifyContent: "space-between",
                    }}
                  >
                    <h1
                      style={{
                        fontSize: 15,
                        backgroundColor: "transparent",
                      }}
                    >
                      {project.property_bhks.bhks.length > 0
                        ? project.property_bhks.bhks.map((itemx, index) => (
                            <div key={itemx.name} style={{ marginRight: 3 }}>
                              {itemx.name + ","}
                            </div>
                          ))
                        : null}{" "}
                      {project.property_type}
                    </h1>
                    <h1
                      style={{
                        fontSize: 15,
                        backgroundColor: "transparent",
                      }}
                    >
                      {project.property_units} units , {project.property_size}{" "}
                      acres
                    </h1>
                  </div>
                  <div
                    onClick={() =>
                      HandleClick(project.id, project.slug)
                    }
                    style={{
                      textDecoration: "underline",
                      backgroundColor: "transparent",
                      alignItems: "end",
                      display: "flex",

                      justifyContent: "end",
                      color: "black", // Inherit text color from parent
                    }}
                  >
                    Show more
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      ) : null}
    </div>
  );
};

export default SimilarProjects;
