import React from "react";

const Statistics = () => {
  const stats = [
    {
      data: "10K+",
      desc: "Handpicked properties",
    },
    {
      data: "10K+",
      desc: "Happy clients and counting",
    },
    {
      data: "5K+",
      desc: "Site visits and counting",
    },
    {
      data: "510",
      desc: "Closure and counting",
    },
  ];

  return (
    <section className="py-28 bg-gray-900">
      <div className="relative z-10 bg-transparent max-w-screen-xl mx-auto px-4 md:px-8">
        <div className="max-w-2xl bg-transparent xl:mx-auto xl:text-center">
          <h3 className="text-white bg-transparent text-3xl font-semibold sm:text-4xl">
            Our commitment to excellence!
          </h3>
          <p className="mt-3 bg-transparent text-gray-300">
            Experience our real estate success story:
          </p>
          <p className="mt-3 bg-transparent text-gray-300">
            Soaring site visits, Delighted customers, Successful closures, and
            an expanding property portfolio
          </p>
        </div>
        <div className="mt-12 bg-transparent">
          <ul className="flex-wrap gap-x-12 bg-transparent gap-y-10 items-center space-y-8 sm:space-y-0 sm:flex xl:justify-center">
            {stats.map((item, idx) => (
              <li key={idx} className="sm:max-w-[15rem] flex flex-col justify-center items-center bg-transparent">
                <h4 className="text-4xl text-white bg-transparent font-semibold">
                  {item.data}
                </h4>
                <p className="mt-3 text-gray-400  bg-transparent font-medium">
                  {item.desc}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div
        className="absolute inset-0 max-w-md mx-auto h-80 blur-[118px] sm:h-72"
        style={{
          background:
            "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)",
        }}
      ></div>
    </section>
  );
};
export default Statistics;
