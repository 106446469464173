export const BASE_URL = 'https://agentnetwork.co.in/api';
//export const BASE_URL = 'http://localhost:3000/api';
export const IMAGE_PATH = 'https://agentnetwork.co.in/';
//export const IMAGE_PATH = 'http://localhost:3000/';
 
export const API_VERSION = 'v1';

export const API_ENDPOINTS = {
  USERS: '/users',
  POSTS: '/posts',
  // Add more endpoints as needed
};