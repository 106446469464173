import React from "react";
import AuthProvider from "./provider/AuthProvider";
import Routesx from "./routes/routes";
import AdminState from "./provider/AdminProvider";

import AssetState from "./provider/AssetProvider";
import GalleryState from "./provider/GalleryProvider";
import BlogsState from "./provider/BlogsPrivider";

function App() {
  return (
    <AuthProvider>
      <AdminState>
        <BlogsState>
          <AssetState>
            <GalleryState>
              <Routesx />
            </GalleryState>
          </AssetState>
        </BlogsState>
      </AdminState>
    </AuthProvider>
  );
}

export default App;
